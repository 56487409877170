export const ELEMENTS_STYLE = {
  receptacle: {
    color: 'rgba(255, 128, 0, 0.5)',
  },
  door: { color: 'rgba(0, 0, 255, 0.5)' },
  wall: { color: 'rgba(154, 87, 205, 0.5)' },
  plumbingFixture: { color: 'rgba(128, 0, 255, 0.5)' },
  area: { color: 'rgba(255, 255, 0, 0.5)' },
  roomName: { color: 'rgba(0, 128, 0, 0.5)' },
  roomNumber: { color: 'rgba(0, 255, 0, 0.5)' },
  wallTag: { color: 'rgba(255, 0, 255, 0.5)' },
  doorTag: { color: 'rgba(0, 128, 192, 0.5)' },
  windowElevation: { color: 'rgba(128, 255, 255, 0.5)' },
  grid: { color: 'rgba(255, 182, 128, 0.5)' },
  text: { color: 'rgba(0, 0, 100, 0.5)' },
  error: { color: 'rgba(223, 0, 0, 0.5)' },
};
