/* eslint-disable no-unsafe-optional-chaining */
import { Box, Button, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import WebViewer from '@pdftron/webviewer';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import document from '../../../assets/document.pdf';
import CrossPointIcon from '../../../assets/icons/CrossPointIcon';
import InfoIcon from '../../../assets/icons/InfoIcon';
import LeftArrowIcon from '../../../assets/icons/LeftArrowIcon';
// import PinIcon from '../../../assets/icons/PinIcon';
import Card from '../../../components/card';
import { ELEMENTS_STYLE } from '../../../constants/elementsStyle';
import { SHEETDATA } from '../../../mock-data';
import { DropdownMenu } from './components/dropdown-menu';
import { ElementsTree } from './components/elementsTree';
import { styles } from './styles';

const ProjectSheet = () => {
  const [selectedElementProps, setSelectedElementProps] = React.useState(SHEETDATA.sheetProperties);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [viewMode] = React.useState('elements');
  const [infoBlock, setInfoBlock] = React.useState(false);
  const viewer = React.useRef(null);
  const navigate = useNavigate();
  const pageParams = useParams();

  // function for DOM drilling through PDF lib wrapper
  const selectPDFContainer = () => {
    const embed1 = window?.document.querySelector('.webviewer > iframe');
    const embed2 = embed1?.contentDocument;
    const embed3 = embed2?.querySelector('#app');
    const embed4 = embed3?.querySelector('#virtualListContainer');
    const embed5 = embed4?.querySelector('#pageWidgetContainer1');
    return embed5;
  };

  // PDF lib rendering
  React.useEffect(() => {
    WebViewer(
      {
        path: '/lib',
        initialDoc: document,
      },
      viewer.current,
    ).then((instance) => {
      instance.UI.disableElements(['toolsHeader']);
      instance.UI.setHeaderItems((header) => {
        const items = header.getItems().slice(3, -5);
        items.push({
          type: 'toolButton',
          toolName: 'MarqueeZoomTool',
          img: 'https://cdn4.iconfinder.com/data/icons/edition-1/128/Edition_zoom_in_selection-512.png',
        });
        header.update(items);
      });
    });
  }, []);

  // function for creating elements for blueprint according to checkboxes
  const renderBlueprintElements = () => {
    // checking for selected elements
    const nodesToShow = selectedItems?.filter(
      (item) => item.type === 'element' && item.state === 'checked',
    );

    // checking for disabled elements
    const nodesToRemove = selectedItems?.filter(
      (item) => item.type === 'element' && item.state !== 'checked',
    );

    const appendSuitableElements = (elementName) => {
      const result = selectedItems.find((item) => item.id === elementName)?.properties?.info;
      setSelectedElementProps(result);
      setInfoBlock(true);
    };

    let elementLeftMargin;
    let elementTopMargin;
    let elementHeight;
    let elementWidth;

    const elementsList = window?.document.createDocumentFragment();
    nodesToShow?.map((element) => {
      elementHeight =
        Number.parseInt(element?.properties?.size?.height, 10) /
        (Number.parseInt(SHEETDATA.dimensions.height, 10) / 100);
      elementWidth =
        Number.parseInt(element?.properties?.size?.width, 10) /
        (Number.parseInt(SHEETDATA.dimensions.width, 10) / 100);
      elementLeftMargin =
        Number.parseInt(element?.properties?.coords?.left, 10) /
        (Number.parseInt(SHEETDATA.dimensions.width, 10) / 100);
      elementTopMargin =
        Number.parseInt(element?.properties?.coords?.top, 10) /
        (Number.parseInt(SHEETDATA.dimensions.height, 10) / 100);

      // checking for element existence to prevent duplicate creating
      if (!selectPDFContainer()?.querySelector(`[name="${element.id}"]`)) {
        const el = window?.document?.createElement('div');
        el.style.border = '0.052vw solid';
        switch (element?.properties?.type) {
          case 'receptacle':
            el.style.backgroundColor = ELEMENTS_STYLE.receptacle.color;
            el.style.borderColor = ELEMENTS_STYLE.receptacle.color;
            break;
          case 'door':
            el.style.backgroundColor = ELEMENTS_STYLE.door.color;
            el.style.borderColor = ELEMENTS_STYLE.door.color;
            break;
          case 'wall':
            el.style.backgroundColor = ELEMENTS_STYLE.wall.color;
            el.style.borderColor = ELEMENTS_STYLE.wall.color;
            break;
          case 'plumbingFixture':
            el.style.backgroundColor = ELEMENTS_STYLE.plumbingFixture.color;
            el.style.borderColor = ELEMENTS_STYLE.plumbingFixture.color;
            break;
          case 'area':
            el.style.backgroundColor = ELEMENTS_STYLE.area.color;
            el.style.borderColor = ELEMENTS_STYLE.area.color;
            break;
          case 'roomName':
            el.style.backgroundColor = ELEMENTS_STYLE.roomName.color;
            el.style.borderColor = ELEMENTS_STYLE.roomName.color;
            break;
          case 'roomNumber':
            el.style.backgroundColor = ELEMENTS_STYLE.roomNumber.color;
            el.style.borderColor = ELEMENTS_STYLE.roomNumber.color;
            break;
          case 'wallTag':
            el.style.backgroundColor = ELEMENTS_STYLE.wallTag.color;
            el.style.borderColor = ELEMENTS_STYLE.wallTag.color;
            break;
          case 'doorTag':
            el.style.backgroundColor = ELEMENTS_STYLE.doorTag.color;
            el.style.borderColor = ELEMENTS_STYLE.doorTag.color;
            break;
          case 'windowElevation':
            el.style.backgroundColor = ELEMENTS_STYLE.windowElevation.color;
            el.style.borderColor = ELEMENTS_STYLE.windowElevation.color;
            break;
          case 'grid':
            el.style.backgroundColor = ELEMENTS_STYLE.grid.color;
            el.style.borderColor = ELEMENTS_STYLE.grid.color;
            break;
          case 'text':
            el.style.backgroundColor = ELEMENTS_STYLE.text.color;
            el.style.borderColor = ELEMENTS_STYLE.text.color;
            break;
          case 'error':
            el.style.backgroundColor = ELEMENTS_STYLE.error.color;
            el.style.borderColor = ELEMENTS_STYLE.error.color;
            break;
          default: {
            el.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
            el.style.borderColor = 'rgb(0, 0, 0)';
          }
        }
        el.style.left = `${elementLeftMargin}%`;
        el.style.top = `${elementTopMargin}%`;
        el.style.height = `${elementHeight}%`;
        el.style.width = `${elementWidth}%`;
        el.style.borderRadius = '0.208vw';
        el.style.position = 'absolute';
        el.style.cursor = 'pointer';
        el.style.zIndex = 10;
        el.setAttribute('name', element.id);
        el.onclick = () => {
          appendSuitableElements(el?.getAttribute('name'));
        };

        const textWrapper = window?.document?.createElement('div');
        textWrapper.style.position = 'absolute';
        textWrapper.style.top = '105%';

        el?.appendChild(textWrapper);

        element?.properties?.info.map((prop) => {
          const propText = window?.document?.createElement('p');
          propText.style.width = `${(prop?.propName + prop?.propValue).length * 1.2}ch`;
          propText.style.margin = 0;
          // propText.style.fontSize = `${infoFontSize}px`;
          propText.style.color = 'red';
          textWrapper?.appendChild(propText);
          propText?.append(`${prop?.propName}: ${prop?.propValue}; `);
          return null;
        });

        elementsList.appendChild(el);
      }
      return null;
    });
    nodesToRemove.map((element) => {
      selectPDFContainer()?.querySelector(`[name="${element.id}"]`)?.remove();
      return null;
    });
    return elementsList;
  };

  React.useLayoutEffect(() => {
    if (viewMode === 'elements') {
      selectPDFContainer()?.appendChild(renderBlueprintElements());
    }
    if (viewMode === 'drawing' && selectPDFContainer()) {
      selectPDFContainer().innerHTML = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.document, viewMode, selectedItems]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElementsTree = React.useCallback(
    <ElementsTree setSelectedItems={setSelectedItems} />,
    [],
  );

  const PropsInfoBlock = () => {
    const handleCloseInfoBlock = () => {
      setInfoBlock(false);
      setSelectedElementProps(SHEETDATA.sheetProperties);
    };

    return (
      <>
        <Button
          variant='primary'
          sx={styles.infoBtn}
          visibility={infoBlock ? 'hidden' : 'visible'}
          onClick={() => setInfoBlock(true)}
        >
          <InfoIcon />
        </Button>
        <Flex sx={styles.infoTab} display={infoBlock ? 'default' : 'none'}>
          <Flex justifyContent='space-between'>
            <Text sx={styles.propsText}>Properties</Text>
            <HStack spacing='1.250vw'>
              {/* <Button sx={styles.infoTabBtn} variant='borderless'>
                <PinIcon />
              </Button> */}
              <Button sx={styles.infoTabBtn} variant='borderless' onClick={handleCloseInfoBlock}>
                <CrossPointIcon />
              </Button>
            </HStack>
          </Flex>
          <VStack mt='0.6vw' spacing='0.625vw'>
            {selectedElementProps?.map((prop, idx) => (
              <Flex key={idx} flexDirection='column'>
                <Text sx={styles.propNameText}>{prop.propName}:</Text>
                <Text sx={styles.propValueText}>{prop.propValue}</Text>
                <Box sx={styles.infoTabDivider} />
              </Flex>
            ))}
          </VStack>
        </Flex>
      </>
    );
  };

  return (
    <Flex sx={styles.sheetPageWrapper} id='ids'>
      <>
        <Card
          wrapperProps={{
            height: '4.167vw',
            width: '98.125vw',
            padding: '0.833vw 0.625vw',
          }}
        >
          <Flex sx={styles.headBlockInnerWrapper}>
            <Flex alignItems='center'>
              <Button
                variant='secondary'
                sx={styles.headBtn}
                onClick={() => navigate(`/projects/${pageParams?.id}`)}
              >
                <LeftArrowIcon />
              </Button>
              <Text sx={styles.sheetNameText}>{SHEETDATA?.sheetName}</Text>
              <Text sx={styles.modeNameText}>[work mode]</Text>
            </Flex>
            <Flex alignItems='center'>
              <DropdownMenu />
            </Flex>
          </Flex>
        </Card>
        <Flex mt='0.625vw'>
          <Card
            wrapperProps={{
              width: '13.021vw',
              height: '88.2vh',
              padding: '0.938vw 0.5vw 0.938vw 0.938vw',
              marginRight: '0.625vw',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            {/* <Text sx={styles.sheetItemsText}>{SHEETDATA?.sheetName} items</Text> */}
            <Box id='check'>{renderElementsTree}</Box>
            {/* <HStack spacing='0.052vw' sx={styles.selectViewBtnsWrapper}>
              <Button
                sx={styles.elementsBtn}
                variant={viewMode === 'elements' ? 'primary' : 'secondary'}
                onClick={() => {
                  setViewMode('elements');
                }}
              >
                Elements
              </Button>
              <Button
                sx={styles.drawingBtn}
                variant={viewMode === 'drawing' ? 'primary' : 'secondary'}
                onClick={() => {
                  setViewMode('drawing');
                }}
              >
                Drawing
              </Button>
            </HStack> */}
          </Card>
          <Card
            wrapperProps={{
              width: '84.479vw',
              // height: '44.375vw',
              height: '88.2vh',
              padding: '0.938vw',
            }}
          >
            <Flex sx={styles.blueprintWrapper}>
              <div
                className='webviewer'
                ref={viewer}
                style={{ height: '100%', width: '100%', position: 'relative' }}
              />
              <PropsInfoBlock />
            </Flex>
          </Card>
        </Flex>
      </>
    </Flex>
  );
};

export default ProjectSheet;
