import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import BookIcon from '../../assets/icons/BookIcon';
import FolderIcon from '../../assets/icons/FolderIcon';
import LogoIcon from '../../assets/icons/LogoIcon';
import NotificationsMenu from '../notifications-menu';
import ProfileMenu from '../profile-menu';
import { styles } from './styles';

const Header = () => {
  const navigate = useNavigate();
  return (
    <Box sx={styles.headerWrapper}>
      <LogoIcon
        onClick={() => {
          navigate('/projects');
        }}
      />
      <Box sx={styles.headerFunctionalWrapper}>
        <a href='https://technodemo.drawer.ai/'>
          <Button variant='primary' sx={styles.projectsBtn} mr='0.6vw'>
            <Flex>
              <Text ml='0.313vw'>Showcase</Text>
            </Flex>
          </Button>
        </a>
        <Button variant='primary' sx={styles.projectsBtn}>
          <Flex>
            <FolderIcon />
            <Text ml='0.313vw'>Projects</Text>
          </Flex>
        </Button>
        <Button variant='secondary' sx={styles.knowledgebaseBtn}>
          <Flex>
            <BookIcon />
            <Text ml='0.313vw'>Knowledgebase</Text>
          </Flex>
        </Button>
        <NotificationsMenu />
        <ProfileMenu />
      </Box>
    </Box>
  );
};

export default Header;
