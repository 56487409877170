/* eslint-disable prettier/prettier */
import s1 from '../assets/images/13-1.png';
import s2 from '../assets/images/16-1.png';
import s3 from '../assets/images/27-1.png';
import s4 from '../assets/images/39-1.png';
import s5 from '../assets/images/56-1.png';
import s6 from '../assets/images/61-1.png';
import s7 from '../assets/images/64-1.png';
import s8 from '../assets/images/66-1.png';
import s9 from '../assets/images/69-1.png';
import s10 from '../assets/images/71-1.png';
import s11 from '../assets/images/72-1.png';
import s12 from '../assets/images/75-1.png';
import s13 from '../assets/images/78-1.png';
import s14 from '../assets/images/79-1.png';
import s15 from '../assets/images/80-1.png';
import s16 from '../assets/images/89-1.png';
import s17 from '../assets/images/93-1.png';
import s18 from '../assets/images/97-1.png';
import s19 from '../assets/images/98-1.png';
import s20 from '../assets/images/108-1.png';
import s21 from '../assets/images/113-1.png';
import s22 from '../assets/images/116-1.png';
import s23 from '../assets/images/120-1.png';
import s24 from '../assets/images/123-1.png';
import s25 from '../assets/images/125-1.png';
import s26 from '../assets/images/148-1.png';
import s27 from '../assets/images/149-1.png';
import s28 from '../assets/images/151-1.png';
import p1 from '../assets/images/p1.jpeg';
import p2 from '../assets/images/p2.jpeg';
import p3 from '../assets/images/p3.jpeg';
import p4 from '../assets/images/p4.jpeg';
import p5 from '../assets/images/p5.jpeg';
import p6 from '../assets/images/p6.jpeg';
import p7 from '../assets/images/p7.jpeg';
import p8 from '../assets/images/p8.jpeg';
import p9 from '../assets/images/p9.jpeg';

export const PROJECTS = [
  {
    id: 1,
    name: 'Legacy Tower',
    address: '2065 Concord Street, Charlotte, NC',
    clientName: 'Pinnacle Builders Inc.',
    creationDate: '18 Sep 2022',
    previewImage: p1,
    filesCount: '140',
    size: '355.1 Mb',
    folders: [
      {
        folderName: 'Source files',
        depthLevel: 0,
        files: null,
        folders: [
          {
            folderName: 'Drawings',
            depthLevel: 1,
            files: [
              {
                fileName: 'Legacy Tower',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Models',
            depthLevel: 1,
            files: [
              {
                fileName: 'The Flax Residence',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Rules',
            depthLevel: 1,
            files: [
              {
                fileName: 'The Flax Residence',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
        ],
      },
      { folderName: 'Drawing index', depthLevel: 0, files: null, folders: null },
      { folderName: 'Elements', depthLevel: 0, files: null, folders: null },
    ],
  },
  {
    id: 2,
    name: 'LAX Terminal 3',
    address: '1 World Way, Los Angeles, CA',
    clientName: 'GHB Inc.',
    creationDate: '08 Jan 2023',
    previewImage: p2,
    filesCount: '780',
    size: '970.3 Mb',
    folders: [
      {
        folderName: 'Source files',
        depthLevel: 0,
        files: null,
        folders: [
          {
            folderName: 'Drawings',
            depthLevel: 1,
            files: [
              {
                fileName: 'Hotel Del Quesadilla',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '12 581 x 16 651 px',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Den Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Models',
            depthLevel: 1,
            files: [
              {
                fileName: 'Hotel Del Quesadilla',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Rules',
            depthLevel: 1,
            files: [
              {
                fileName: 'Hotel Del Quesadilla',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
        ],
      },
      { folderName: 'Drawing index', depthLevel: 0, files: null, folders: null },
      { folderName: 'Elements', depthLevel: 0, files: null, folders: null },
    ],
  },
  {
    id: 3,
    name: 'Middle Out Data Center',
    address: '685 Water Street, Concord, CA',
    clientName: 'Fortier Construction',
    creationDate: '04 May 2021',
    previewImage: p3,
    filesCount: '1,870',
    size: '2,137.7 Mb',
    folders: [
      {
        folderName: 'Source files',
        depthLevel: 0,
        files: null,
        folders: [
          {
            folderName: 'Drawings',
            depthLevel: 1,
            files: [
              {
                fileName: 'St. Rose Philippine Hospital',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Models',
            depthLevel: 1,
            files: [
              {
                fileName: 'St. Rose Philippine Hospital',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Rules',
            depthLevel: 1,
            files: [
              {
                fileName: 'St. Rose Philippine Hospital',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
        ],
      },
      { folderName: 'Drawing index', depthLevel: 0, files: null, folders: null },
      { folderName: 'Elements', depthLevel: 0, files: null, folders: null },
    ],
  },
  {
    id: 4,
    name: 'Skyline Residences',
    address: '701 N Indiana Ave, Honolulu, HI',
    clientName: 'Nexus Commercial',
    creationDate: '02 Mar 2022',
    previewImage: p4,
    filesCount: '560',
    size: '810.5 Mb',
    folders: [
      {
        folderName: 'Source files',
        depthLevel: 0,
        files: null,
        folders: [
          {
            folderName: 'Drawings',
            depthLevel: 1,
            files: [
              {
                fileName: 'St. Catherine High School',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'TITLE SHEET',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 01 -POWER PLAN',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 02 -POWER PLAN ',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 03 -POWER PLAN',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 04 -POWER PLAN',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 05 -POWER PLAN',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 06 -POWER PLAN',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 07 -POWER PLAN',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 08 -POWER PLAN',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 09 -POWER PLAN',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 10 -POWER PLAN',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 11 -POWER PLAN',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL P1 -POWER PLAN',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'ROOF -POWER PLAN',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'SINGLE LINE DIAGRAM A',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'SINGLE LINE DIAGRAM B',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'PANEL SCHEDULES',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'PANEL SCHEDULES',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'PANEL SCHEDULES',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'CIRCUIT CALCULATIONS E',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'DETAIL SHEET',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Rules',
            depthLevel: 1,
            files: [
              {
                fileName: 'St. Catherine High School',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'LEVEL 01 -POWER PLAN',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 02 -POWER PLAN ',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 03 -POWER PLAN',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 04 -POWER PLAN',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 05 -POWER PLAN',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 06 -POWER PLAN',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 07 -POWER PLAN',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 08 -POWER PLAN',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 09 -POWER PLAN',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 10 -POWER PLAN',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 11 -POWER PLAN',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL P1 -POWER PLAN',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'ROOF -POWER PLAN',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'SINGLE LINE DIAGRAM A',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'SINGLE LINE DIAGRAM B',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'PANEL SCHEDULES',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'PANEL SCHEDULES',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'PANEL SCHEDULES',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'CIRCUIT CALCULATIONS E',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'TITLE SHEET',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
        ],
      },
      { folderName: 'Drawing index', depthLevel: 0, files: null, folders: null },
      { folderName: 'Elements', depthLevel: 0, files: null, folders: null },
    ],
  },
  {
    id: 5,
    name: 'Spring Forest Hospital',
    address: '1601 Villa Drive, South Bend, IN',
    clientName: 'Redwood Builders',
    creationDate: '12 Mar 2021',
    previewImage: p5,
    filesCount: '810',
    size: '1,102.2 Mb',
    folders: [
      {
        folderName: 'Source files',
        depthLevel: 0,
        files: null,
        folders: [
          {
            folderName: 'Drawings',
            depthLevel: 1,
            files: [
              {
                fileName: 'NGH Clinic',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Models',
            depthLevel: 1,
            files: [
              {
                fileName: 'NGH Clinic',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Rules',
            depthLevel: 1,
            files: [
              {
                fileName: 'NGH Clinic',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
        ],
      },
      { folderName: 'Drawing index', depthLevel: 0, files: null, folders: null },
      { folderName: 'Elements', depthLevel: 0, files: null, folders: null },
    ],
  },
  {
    id: 6,
    name: 'White Willow Hospital',
    address: '8200 Walnut Hill Ln, Milwaukee, WI',
    clientName: 'Builders Innovations Inc.',
    creationDate: '11 June 2021',
    previewImage: p6,
    filesCount: '255',
    size: '752.2 Mb',
    folders: [
      {
        folderName: 'Source files',
        depthLevel: 0,
        files: null,
        folders: [
          {
            folderName: 'Drawings',
            depthLevel: 1,
            files: [
              {
                fileName: 'HH Distribution Center',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Models',
            depthLevel: 1,
            files: [
              {
                fileName: 'HH Distribution Center',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Rules',
            depthLevel: 1,
            files: [
              {
                fileName: 'HH Distribution Center',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
        ],
      },
      { folderName: 'Drawing index', depthLevel: 0, files: null, folders: null },
      { folderName: 'Elements', depthLevel: 0, files: null, folders: null },
    ],
  },
  {
    id: 7,
    name: 'Silver Boulder Hospital',
    address: '3104 Vernon Street, San Diego, CA',
    clientName: 'Prime Construction Group',
    creationDate: '12 May 2022',
    previewImage: p7,
    filesCount: '185',
    size: '452.2 Mb',
    folders: [
      {
        folderName: 'Source files',
        depthLevel: 0,
        files: null,
        folders: [
          {
            folderName: 'Drawings',
            depthLevel: 1,
            files: [
              {
                fileName: 'MELTA HQ Office',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Models',
            depthLevel: 1,
            files: [
              {
                fileName: 'MELTA HQ Office',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Rules',
            depthLevel: 1,
            files: [
              {
                fileName: 'MELTA HQ Office',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
        ],
      },
      { folderName: 'Drawing index', depthLevel: 0, files: null, folders: null },
      { folderName: 'Elements', depthLevel: 0, files: null, folders: null },
    ],
  },
  {
    id: 8,
    name: 'Evergreen High School',
    address: '1768 Millbrook Road, Burr Ridge, IL',
    clientName: 'Cornerstone Builders',
    creationDate: '10 Sep 2022',
    previewImage: p8,
    filesCount: '215',
    size: '312.2 Mb',
    folders: [
      {
        folderName: 'Source files',
        depthLevel: 0,
        files: null,
        folders: [
          {
            folderName: 'Drawings',
            depthLevel: 1,
            files: [
              {
                fileName: 'HPX Warehouse',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Models',
            depthLevel: 1,
            files: [
              {
                fileName: 'HPX Warehouse',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Rules',
            depthLevel: 1,
            files: [
              {
                fileName: 'HPX Warehouse',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
        ],
      },
      { folderName: 'Drawing index', depthLevel: 0, files: null, folders: null },
      { folderName: 'Elements', depthLevel: 0, files: null, folders: null },
    ],
  },
  {
    id: 9,
    name: '399 Fremont',
    address: '399 Fremont St, San Francisco, CA',
    clientName: 'Sierra Construction Services',
    creationDate: '06 Jan 2020',
    previewImage: p9,
    filesCount: '105',
    size: '112.2 Mb',
    folders: [
      {
        folderName: 'Source files',
        depthLevel: 0,
        files: null,
        folders: [
          {
            folderName: 'Drawings',
            depthLevel: 1,
            files: [
              {
                fileName: 'BGH Hospital',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Models',
            depthLevel: 1,
            files: [
              {
                fileName: 'BGH Hospital',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'LEVEL 05 -POWER PLAN -ENLARGEMENTA',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 05 -POWER PLAN -ENLARGEMENTB',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 05 -OVERALLPOWER PLAN',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 04 -POWER PLAN -ENLARGEMENTA',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 03 -OVERALLPOWER PLAN',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 03 -POWER PLAN -ENLARGEMENTA',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 03 -POWER PLAN -ENLARGEMENTB',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 02 -OVERALLPOWER PLAN',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 02 -POWER PLAN -ENLARGEMENTA',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 02 -POWER PLAN -ENLARGEMENTB',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL 01 -POWER PLAN -ENLARGEMENTB',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'LEVEL P1 -OVERALLPOWER PLAN',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'ROOF -OVERALL POWER PLAN',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'SINGLE LINE DIAGRAM A',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'SINGLE LINE DIAGRAM B',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'PANEL SCHEDULES',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'PANEL SCHEDULES',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'PANEL SCHEDULES',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'SHORT CIRCUIT CALCULATIONS E',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'DETAIL SHEET',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Rules',
            depthLevel: 1,
            files: [
              {
                fileName: 'BGH Hospital',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrical',
                    latestRevision: '3',
                    pageSize: '30 x 42 inches',
                    scale: '1/8 = 1-0',
                    uploadDate: '02 Mar 2022',
                    uploadedBy: 'Dan Lavrik',
                  },
                ],
              },
            ],
            folders: null,
          },
        ],
      },
      { folderName: 'Drawing index', depthLevel: 0, files: null, folders: null },
      { folderName: 'Elements', depthLevel: 0, files: null, folders: null },
    ],
  },
];
