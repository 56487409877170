/* eslint-disable prettier/prettier */
export const SHEETDATA = {
  sheetName: 'A151',
  dimensions: {
    width: 10668,
    height: 7620,
  },
  sheetProperties: [
    {
      propName: 'Plug',
      propValue: '40',
    },
    {
      propName: 'Location',
      propValue: '38',
    },
    {
      propName: 'Power',
      propValue: '250w',
    },
    {
      propName: 'Sheet',
      propValue: 'A151',
    },
  ],
  elements: [
    { id: 'Receptacles', name: 'Receptacles', parentId: null, type: 'folder' },
    { id: 'Doors', name: 'Doors', parentId: null, type: 'folder' },
    { id: 'Walls', name: 'Walls', parentId: null, type: 'folder' },
    { id: 'Plumbing Fixtures', name: 'Plumbing Fixtures', parentId: null, type: 'folder' },
    { id: 'Areas', name: 'Areas', parentId: null, type: 'folder' },
    { id: 'Room Names', name: 'Room Names', parentId: null, type: 'folder' },
    { id: 'Room Numbers', name: 'Room Numbers', parentId: null, type: 'folder' },
    { id: 'Wall Tags', name: 'Wall Tags', parentId: null, type: 'folder' },
    { id: 'Door Tags', name: 'Door Tags', parentId: null, type: 'folder' },
    { id: 'Window Elevations', name: 'Window Elevations', parentId: null, type: 'folder' },
    { id: 'Grids', name: 'Grids', parentId: null, type: 'folder' },
    { id: 'Texts', name: 'Texts', parentId: null, type: 'folder' },
    { id: 'Errors', name: 'Errors', parentId: null, type: 'folder' },

    {
      id: 'Error 001',
      name: 'Error 001',
      parentId: 'Errors',
      type: 'element',
      properties: {
        coords: {
          top: '2163',
          left: '2382',
        },
        size: {
          height: '170',
          width: '170',
        },
        type: 'error',
        info: [],
      },
    },
    {
      id: 'Error 002',
      name: 'Error 002',
      parentId: 'Errors',
      type: 'element',
      properties: {
        coords: {
          top: '3087',
          left: '3569',
        },
        size: {
          height: '170',
          width: '170',
        },
        type: 'error',
        info: [],
      },
    },
    {
      id: 'Error 003',
      name: 'Error 003',
      parentId: 'Errors',
      type: 'element',
      properties: {
        coords: {
          top: '3202',
          left: '3482',
        },
        size: {
          height: '240',
          width: '240',
        },
        type: 'error',
        info: [],
      },
    },
    {
      id: 'Error 004',
      name: 'Error 004',
      parentId: 'Errors',
      type: 'element',
      properties: {
        coords: {
          top: '3070',
          left: '4116',
        },
        size: {
          height: '240',
          width: '240',
        },
        type: 'error',
        info: [],
      },
    },
    {
      id: 'PF001',
      name: 'PF001',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '3208.4390000000003',
          left: '2472.788',
        },
        size: {
          height: '62.452',
          width: '82.204',
        },
        type: 'plumbingFixture',
        info: [],
      },
    },
    {
      id: 'PF002',
      name: 'PF002',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '3159.4349999999995',
          left: '2775.9519999999998',
        },
        size: {
          height: '62.452',
          width: '82.204',
        },
        type: 'plumbingFixture',
        info: [],
      },
    },
    {
      id: 'PF003',
      name: 'PF003',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '2992.0',
          left: '7331.0019999999995',
        },
        size: {
          height: '60.0',
          width: '79.999',
        },
        type: 'plumbingFixture',
        info: [],
      },
    },
    {
      id: 'PF004',
      name: 'PF004',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '2992.96',
          left: '7228.76',
        },
        size: {
          height: '60.0',
          width: '79.999',
        },
        type: 'plumbingFixture',
        info: [],
      },
    },
    {
      id: 'PF005',
      name: 'PF005',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '2712.628',
          left: '7468.433',
        },
        size: {
          height: '62.452',
          width: '50.087',
        },
        type: 'plumbingFixture',
        info: [],
      },
    },
    {
      id: 'PF006',
      name: 'PF006',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '2708.804',
          left: '7522.729',
        },
        size: {
          height: '62.452',
          width: '50.087',
        },
        type: 'plumbingFixture',
        info: [],
      },
    },
    {
      id: 'PF007',
      name: 'PF007',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '3198.968',
          left: '7229.4619999999995',
        },
        size: {
          height: '60.001',
          width: '79.999',
        },
        type: 'plumbingFixture',
        info: [],
      },
    },
    {
      id: 'PF008',
      name: 'PF008',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '3199.751',
          left: '7331.008000000001',
        },
        size: {
          height: '60.0',
          width: '79.999',
        },
        type: 'plumbingFixture',
        info: [],
      },
    },
    {
      id: 'PF009',
      name: 'PF009',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '3171.4260000000004',
          left: '7109.099999999999',
        },
        size: {
          height: '79.999',
          width: '70.00200000000001',
        },
        type: 'plumbingFixture',
        info: [],
      },
    },
    {
      id: 'PF010',
      name: 'PF010',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '3172.2019999999998',
          left: '6998.914',
        },
        size: {
          height: '79.999',
          width: '70.00200000000001',
        },
        type: 'plumbingFixture',
        info: [],
      },
    },
    {
      id: 'PF011',
      name: 'PF011',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '3172.2019999999998',
          left: '6887.341',
        },
        size: {
          height: '79.999',
          width: '70.001',
        },
        type: 'plumbingFixture',
        info: [],
      },
    },
    {
      id: 'PF012',
      name: 'PF012',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '3173.734',
          left: '6716.286',
        },
        size: {
          height: '79.999',
          width: '70.00200000000001',
        },
        type: 'plumbingFixture',
        info: [],
      },
    },
    {
      id: 'PF013',
      name: 'PF013',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '3024.911',
          left: '6716.064',
        },
        size: {
          height: '79.999',
          width: '70.00200000000001',
        },
        type: 'plumbingFixture',
        info: [],
      },
    },
    {
      id: 'PF014',
      name: 'PF014',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '3025.38',
          left: '6888.384999999999',
        },
        size: {
          height: '79.999',
          width: '69.99799999999999',
        },
        type: 'plumbingFixture',
        info: [],
      },
    },
    {
      id: 'PF015',
      name: 'PF015',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '3025.7110000000002',
          left: '6999.485',
        },
        size: {
          height: '79.999',
          width: '70.00200000000001',
        },
        type: 'plumbingFixture',
        info: [],
      },
    },
    {
      id: 'PF016',
      name: 'PF016',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '3025.8940000000002',
          left: '7109.587',
        },
        size: {
          height: '79.999',
          width: '69.99799999999999',
        },
        type: 'plumbingFixture',
        info: [],
      },
    },
    {
      id: 'PF017',
      name: 'PF017',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '4046.454',
          left: '2981.795',
        },
        size: {
          height: '93.378',
          width: '73.051',
        },
        type: 'plumbingFixture',
        info: [],
      },
    },
    {
      id: 'PF018',
      name: 'PF018',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '3102.4970000000003',
          left: '7225.648',
        },
        size: {
          height: '83.746',
          width: '84.352',
        },
        type: 'plumbingFixture',
        info: [],
      },
    },
    {
      id: 'RC001',
      name: 'RC001',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '1931.0',
          left: '2701.25',
        },
        size: {
          height: '40.001',
          width: '40.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC002',
      name: 'RC002',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '1934.4990000000003',
          left: '2991.5',
        },
        size: {
          height: '30.0',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC003',
      name: 'RC003',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '1934.4990000000003',
          left: '3022.6040000000003',
        },
        size: {
          height: '29.996000000000002',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC004',
      name: 'RC004',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '1902.749',
          left: '3690.652',
        },
        size: {
          height: '30.0',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC005',
      name: 'RC005',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '1902.749',
          left: '3721.757',
        },
        size: {
          height: '30.0',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC006',
      name: 'RC006',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '1873.7469999999998',
          left: '3759.292',
        },
        size: {
          height: '30.0',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC007',
      name: 'RC007',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '1872.999',
          left: '4457.894',
        },
        size: {
          height: '30.0',
          width: '29.997',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC008',
      name: 'RC008',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '1860.239',
          left: '6686.293',
        },
        size: {
          height: '30.0',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC009',
      name: 'RC009',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '1873.5',
          left: '3077.0409999999997',
        },
        size: {
          height: '30.0',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC010',
      name: 'RC010',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '1903.25',
          left: '4389.043',
        },
        size: {
          height: '30.0',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC011',
      name: 'RC011',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '1903.25',
          left: '4420.147',
        },
        size: {
          height: '30.0',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC012',
      name: 'RC012',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '1903.0',
          left: '5087.938',
        },
        size: {
          height: '30.0',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC013',
      name: 'RC013',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '1903.0',
          left: '5119.042',
        },
        size: {
          height: '30.0',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC014',
      name: 'RC014',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '1903.998',
          left: '6026.862999999999',
        },
        size: {
          height: '30.001',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC015',
      name: 'RC015',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '1903.998',
          left: '6057.968',
        },
        size: {
          height: '30.001',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC016',
      name: 'RC016',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '1881.5010000000002',
          left: '7077.291',
        },
        size: {
          height: '30.0',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC017',
      name: 'RC017',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '1930.001',
          left: '6727.794',
        },
        size: {
          height: '30.0',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC018',
      name: 'RC018',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '2473.0',
          left: '7152.291',
        },
        size: {
          height: '30.0',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC019',
      name: 'RC019',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '2662.5689999999995',
          left: '7152.291',
        },
        size: {
          height: '29.997',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC020',
      name: 'RC020',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '2756.0019999999995',
          left: '7893.289999999999',
        },
        size: {
          height: '29.997',
          width: '30.001',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC021',
      name: 'RC021',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '2759.005',
          left: '6883.002',
        },
        size: {
          height: '29.996000000000002',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC022',
      name: 'RC022',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '2759.005',
          left: '6509.76',
        },
        size: {
          height: '29.996000000000002',
          width: '29.999',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC023',
      name: 'RC023',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '2759.005',
          left: '6133.099',
        },
        size: {
          height: '29.996000000000002',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC024',
      name: 'RC024',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '3418.0040000000004',
          left: '6202.0',
        },
        size: {
          height: '29.997',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC025',
      name: 'RC025',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '2768.0040000000004',
          left: '4653.001',
        },
        size: {
          height: '29.996000000000002',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC026',
      name: 'RC026',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '2768.0040000000004',
          left: '4255.164',
        },
        size: {
          height: '29.996000000000002',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC027',
      name: 'RC027',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '2769.5060000000003',
          left: '3857.9849999999997',
        },
        size: {
          height: '29.993',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC028',
      name: 'RC028',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '2769.5060000000003',
          left: '3461.305',
        },
        size: {
          height: '29.993',
          width: '30.001',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC029',
      name: 'RC029',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '2929.008',
          left: '2768.499',
        },
        size: {
          height: '29.993',
          width: '30.001',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC030',
      name: 'RC030',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '3244.0080000000003',
          left: '3378.0',
        },
        size: {
          height: '29.993',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC031',
      name: 'RC031',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '3301.259',
          left: '3332.0',
        },
        size: {
          height: '29.993',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC032',
      name: 'RC032',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '3313.9990000000003',
          left: '3705.0009999999997',
        },
        size: {
          height: '29.993',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC033',
      name: 'RC033',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '3313.9990000000003',
          left: '3660.6380000000004',
        },
        size: {
          height: '29.993',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC034',
      name: 'RC034',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '3266.006',
          left: '4294.999',
        },
        size: {
          height: '29.993',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC035',
      name: 'RC035',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '3266.006',
          left: '4619.367',
        },
        size: {
          height: '29.993',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC036',
      name: 'RC036',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '4010.0',
          left: '8377.001',
        },
        size: {
          height: '29.993',
          width: '30.001',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC037',
      name: 'RC037',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '4010.0',
          left: '8348.303',
        },
        size: {
          height: '29.993',
          width: '30.001',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC038',
      name: 'RC038',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '4010.0',
          left: '8208.292',
        },
        size: {
          height: '29.993',
          width: '30.001',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC039',
      name: 'RC039',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '4010.0',
          left: '8177.975',
        },
        size: {
          height: '29.993',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC040',
      name: 'RC040',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '4012.0069999999996',
          left: '6724.000999999999',
        },
        size: {
          height: '29.993',
          width: '29.999',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC041',
      name: 'RC041',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '4012.0069999999996',
          left: '6753.159000000001',
        },
        size: {
          height: '29.993',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC042',
      name: 'RC042',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '4118.007',
          left: '6659.841',
        },
        size: {
          height: '29.993',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC043',
      name: 'RC043',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '4118.007',
          left: '6689.002',
        },
        size: {
          height: '29.993',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC044',
      name: 'RC044',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '4152.2570000000005',
          left: '6031.001',
        },
        size: {
          height: '29.993',
          width: '29.997',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC045',
      name: 'RC045',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '4117.507',
          left: '4389.84',
        },
        size: {
          height: '29.993',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC046',
      name: 'RC046',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '4117.507',
          left: '4419.0',
        },
        size: {
          height: '29.993',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC047',
      name: 'RC047',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '4117.007',
          left: '3691.248',
        },
        size: {
          height: '29.993',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC048',
      name: 'RC048',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '4117.007',
          left: '3720.409',
        },
        size: {
          height: '29.993',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC049',
      name: 'RC049',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '4133.757',
          left: '4457.749',
        },
        size: {
          height: '29.993',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC050',
      name: 'RC050',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '4152.507',
          left: '3062.251',
        },
        size: {
          height: '29.993',
          width: '29.999',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC051',
      name: 'RC051',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '4151.76',
          left: '3760.4210000000003',
        },
        size: {
          height: '29.993',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC052',
      name: 'RC052',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '1807.009',
          left: '8285.748',
        },
        size: {
          height: '29.993',
          width: '30.0',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC053',
      name: 'RC053',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '3920.067',
          left: '5126.151',
        },
        size: {
          height: '29.993',
          width: '29.997',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC054',
      name: 'RC054',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '2102.249',
          left: '5646.752',
        },
        size: {
          height: '40.001',
          width: '39.998',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC055',
      name: 'RC055',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '2412.368',
          left: '5646.752',
        },
        size: {
          height: '40.002',
          width: '39.998',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC056',
      name: 'RC056',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '3068.186',
          left: '5646.500999999999',
        },
        size: {
          height: '40.004999999999995',
          width: '39.998',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC057',
      name: 'RC057',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '3904.361',
          left: '5646.500999999999',
        },
        size: {
          height: '40.004999999999995',
          width: '39.998',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC058',
      name: 'RC058',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '3111.299',
          left: '6198.27',
        },
        size: {
          height: '40.004999999999995',
          width: '39.998',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC059',
      name: 'RC059',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '4004.9939999999997',
          left: '6963.749',
        },
        size: {
          height: '40.004999999999995',
          width: '39.998',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC060',
      name: 'RC060',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '4004.747',
          left: '7749.501',
        },
        size: {
          height: '40.001',
          width: '39.998',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'RC061',
      name: 'RC061',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '2934.744',
          left: '8093.252',
        },
        size: {
          height: '40.004999999999995',
          width: '39.998',
        },
        type: 'receptacle',
        info: [],
      },
    },
    {
      id: 'DT001',
      name: 'DT001',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2358.499',
          left: '3657.7480000000005',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT002',
      name: 'DT002',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2359.0',
          left: '4059.202',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT003',
      name: 'DT003',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2112.899',
          left: '3667.6440000000002',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT004',
      name: 'DT004',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2114.821',
          left: '4364.937',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT005',
      name: 'DT005',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2356.739',
          left: '4452.225',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT006',
      name: 'DT006',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2357.226',
          left: '4849.569',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT007',
      name: 'DT007',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2129.712',
          left: '2892.9489999999996',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT008',
      name: 'DT008',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2130.537',
          left: '2429.6549999999997',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT009',
      name: 'DT009',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2462.732',
          left: '2828',
        },
        size: {
          height: '65',
          width: '110',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT010',
      name: 'DT010',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2935.559',
          left: '2433.091',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT011',
      name: 'DT011',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3841.1110000000003',
          left: '3667.139',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT012',
      name: 'DT012',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3841.3650000000002',
          left: '4367.52',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT013',
      name: 'DT013',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3072.645',
          left: '4136.119',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT014',
      name: 'DT014',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3382.842',
          left: '4530.68',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT015',
      name: 'DT015',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3385.044',
          left: '4859.3060000000005',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT016',
      name: 'DT016',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3817.2879999999996',
          left: '6624.278',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT017',
      name: 'DT017',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3733.055',
          left: '7111.7',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT018',
      name: 'DT018',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3735.906',
          left: '7305.213',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT019',
      name: 'DT019',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3368.114',
          left: '6620.811',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT020',
      name: 'DT020',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2821.205',
          left: '5986.527',
        },
        size: {
          height: '65',
          width: '110',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT021',
      name: 'DT021',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2361.141',
          left: '6309.4619999999995',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT022',
      name: 'DT022',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2361.544',
          left: '6686.018',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT023',
      name: 'DT023',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2358.489',
          left: '7063.024',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT024',
      name: 'DT024',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2417.799',
          left: '7379.769',
        },
        size: {
          height: '65',
          width: '110',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT025',
      name: 'DT025',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3387.901',
          left: '8074.022',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT026',
      name: 'DT026',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '1980.248',
          left: '7102.997',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT027',
      name: 'DT027',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2103.897',
          left: '6989.999',
        },
        size: {
          height: '65',
          width: '110',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT028',
      name: 'DT028',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2097.553',
          left: '6093.16',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DT029',
      name: 'DT029',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2103.391',
          left: '5063.303',
        },
        size: {
          height: '110',
          width: '65',
        },
        type: 'doorTag',
        info: [],
      },
    },
    {
      id: 'DR001',
      name: 'DR001',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2167.9990000000003',
          left: '2414.0',
        },
        size: {
          height: '131.999',
          width: '118.0',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR002',
      name: 'DR002',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2019.998',
          left: '2298.0',
        },
        size: {
          height: '114.0',
          width: '123.0',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR003',
      name: 'DR003',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2159.0',
          left: '2878.0',
        },
        size: {
          height: '131.998',
          width: '118.0',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR004',
      name: 'DR004',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2080.002',
          left: '3626.0019999999995',
        },
        size: {
          height: '131.999',
          width: '117.997',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR005',
      name: 'DR005',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2068.999',
          left: '5017.001',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR006',
      name: 'DR006',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '1650.001',
          left: '5213.001',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR007',
      name: 'DR007',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2076.002',
          left: '6076.001',
        },
        size: {
          height: '131.999',
          width: '118.0',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR008',
      name: 'DR008',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2075.0',
          left: '4324.001',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR009',
      name: 'DR009',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '1923.002',
          left: '7077.001',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR010',
      name: 'DR010',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2376.001',
          left: '7017.999',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR011',
      name: 'DR011',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2378.999',
          left: '6629.000999999999',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR012',
      name: 'DR012',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2378.999',
          left: '6261.0019999999995',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR013',
      name: 'DR013',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2372.998',
          left: '4807.998',
        },
        size: {
          height: '131.999',
          width: '118.00399999999999',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR014',
      name: 'DR014',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2376.001',
          left: '4413.0',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR015',
      name: 'DR015',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2374.0',
          left: '4016.001',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR016',
      name: 'DR016',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2374.0',
          left: '3616.9990000000003',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR017',
      name: 'DR017',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2949.0',
          left: '2411.9990000000003',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR018',
      name: 'DR018',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2997.002',
          left: '3066.0',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR019',
      name: 'DR019',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '3088.0009999999997',
          left: '3986.001',
        },
        size: {
          height: '131.995',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR020',
      name: 'DR020',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '3089.0',
          left: '4120.0',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR021',
      name: 'DR021',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '3343.9980000000005',
          left: '6576.999',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR022',
      name: 'DR022',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '3254.001',
          left: '7459.0',
        },
        size: {
          height: '131.999',
          width: '118.00399999999999',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR023',
      name: 'DR023',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2867.0',
          left: '7460.001',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR024',
      name: 'DR024',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '3749.001',
          left: '7291.998',
        },
        size: {
          height: '131.999',
          width: '118.00399999999999',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR025',
      name: 'DR025',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '3747.001',
          left: '7066.0019999999995',
        },
        size: {
          height: '131.999',
          width: '118.0',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR026',
      name: 'DR026',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '3835.0009999999997',
          left: '6583.0',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR027',
      name: 'DR027',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '3345.998',
          left: '4486.0',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR028',
      name: 'DR028',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '3347.998',
          left: '4812.002',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR029',
      name: 'DR029',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '3842.999',
          left: '4322.999',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR030',
      name: 'DR030',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '3844.001',
          left: '3625.0',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR031',
      name: 'DR031',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '4271.0',
          left: '2527.9990000000003',
        },
        size: {
          height: '131.999',
          width: '118.001',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR032',
      name: 'DR032',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2454.0',
          left: '2851.0',
        },
        size: {
          height: '114.0',
          width: '123.0',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR033',
      name: 'DR033',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2972.001',
          left: '2601.0',
        },
        size: {
          height: '114.0',
          width: '123.0',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR034',
      name: 'DR034',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '3797.998',
          left: '2301.5',
        },
        size: {
          height: '114.0',
          width: '123.0',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR035',
      name: 'DR035',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2804.9990000000003',
          left: '6003.999',
        },
        size: {
          height: '114.0',
          width: '123.0',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'DR036',
      name: 'DR036',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '2064.998',
          left: '6961.001',
        },
        size: {
          height: '114.0',
          width: '122.999',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'WL001',
      name: 'WL001',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1767.7510000000002',
          left: '3746.0',
        },
        size: {
          height: '409.998',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL002',
      name: 'WL002',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1767.998',
          left: '4444.997',
        },
        size: {
          height: '410.006',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL003',
      name: 'WL003',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1767.998',
          left: '6015.996999999999',
        },
        size: {
          height: '410.006',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL004',
      name: 'WL004',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1768.002',
          left: '6714.9980000000005',
        },
        size: {
          height: '410.002',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL005',
      name: 'WL005',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1768.002',
          left: '7063.9980000000005',
        },
        size: {
          height: '301.999',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL006',
      name: 'WL006',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1767.998',
          left: '7224.0',
        },
        size: {
          height: '41.004',
          width: '6.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL007',
      name: 'WL007',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1768.002',
          left: '7261.0',
        },
        size: {
          height: '41.003',
          width: '6.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL008',
      name: 'WL008',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1767.998',
          left: '6176.0',
        },
        size: {
          height: '41.007',
          width: '6.0009999999999994',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL009',
      name: 'WL009',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1767.998',
          left: '6212.999',
        },
        size: {
          height: '41.004',
          width: '6.0009999999999994',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL010',
      name: 'WL010',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1767.998',
          left: '5128.25',
        },
        size: {
          height: '41.007',
          width: '6.0009999999999994',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL011',
      name: 'WL011',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1767.998',
          left: '5166.001',
        },
        size: {
          height: '41.004',
          width: '6.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL012',
      name: 'WL012',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1767.998',
          left: '4081.001',
        },
        size: {
          height: '41.007',
          width: '6.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL013',
      name: 'WL013',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1768.005',
          left: '4118.0',
        },
        size: {
          height: '41.004',
          width: '6.0009999999999994',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL014',
      name: 'WL014',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1767.998',
          left: '3032.999',
        },
        size: {
          height: '41.007',
          width: '6.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL015',
      name: 'WL015',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1767.998',
          left: '3070.0',
        },
        size: {
          height: '34.001000000000005',
          width: '6.0009999999999994',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL016',
      name: 'WL016',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2389.0',
          left: '3344.998',
        },
        size: {
          height: '410.002',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL017',
      name: 'WL017',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2389.0',
          left: '3741.0',
        },
        size: {
          height: '410.002',
          width: '13.998999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL018',
      name: 'WL018',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2389.0',
          left: '4137.999',
        },
        size: {
          height: '411.0',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL019',
      name: 'WL019',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2389.0',
          left: '4534.751',
        },
        size: {
          height: '410.002',
          width: '14.001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL020',
      name: 'WL020',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2389.0',
          left: '4932.0',
        },
        size: {
          height: '422.998',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL021',
      name: 'WL021',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2389.0',
          left: '6015.996999999999',
        },
        size: {
          height: '419.00100000000003',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL022',
      name: 'WL022',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2389.0',
          left: '6392.249000000001',
        },
        size: {
          height: '400.00100000000003',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL023',
      name: 'WL023',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2389.0',
          left: '6765.749',
        },
        size: {
          height: '398.999',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL024',
      name: 'WL024',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2389.0',
          left: '7138.251',
        },
        size: {
          height: '398.999',
          width: '13.998',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL025',
      name: 'WL025',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3110.999',
          left: '4280.9980000000005',
        },
        size: {
          height: '355.0',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL026',
      name: 'WL026',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3110.999',
          left: '4605.9980000000005',
        },
        size: {
          height: '355.0',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL027',
      name: 'WL027',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3110.999',
          left: '4932.0',
        },
        size: {
          height: '355.0',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL028',
      name: 'WL028',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3116.9990000000003',
          left: '6411.998',
        },
        size: {
          height: '331.001',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL029',
      name: 'WL029',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2787.999',
          left: '6410.999000000001',
        },
        size: {
          height: '329.00100000000003',
          width: '15.004',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL030',
      name: 'WL030',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2787.999',
          left: '6690.001',
        },
        size: {
          height: '673.0020000000001',
          width: '13.25',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL031',
      name: 'WL031',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3853.0',
          left: '3746.0',
        },
        size: {
          height: '433.001',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL032',
      name: 'WL032',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3853.0',
          left: '4443.9980000000005',
        },
        size: {
          height: '433.00199999999995',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL033',
      name: 'WL033',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3853.0',
          left: '6015.996999999999',
        },
        size: {
          height: '433.001',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL034',
      name: 'WL034',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3756.0',
          left: '6714.9980000000005',
        },
        size: {
          height: '530.0',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL035',
      name: 'WL035',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3766.001',
          left: '7238.997',
        },
        size: {
          height: '82.99799999999999',
          width: '14.004000000000001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL036',
      name: 'WL036',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3894.998',
          left: '7238.997',
        },
        size: {
          height: '391.002',
          width: '14.004000000000001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL037',
      name: 'WL037',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3766.001',
          left: '8249.995',
        },
        size: {
          height: '520.999',
          width: '14.005',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL038',
      name: 'WL038',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3766.001',
          left: '8322.938',
        },
        size: {
          height: '520.999',
          width: '14.009',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL039',
      name: 'WL039',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3855.251',
          left: '6154.004999999999',
        },
        size: {
          height: '47.001',
          width: '8.004999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL040',
      name: 'WL040',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3855.251',
          left: '6233.002',
        },
        size: {
          height: '47.001',
          width: '8.008',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL041',
      name: 'WL041',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3831.0',
          left: '5123.995',
        },
        size: {
          height: '90.999',
          width: '8.004',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL042',
      name: 'WL042',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3831.0',
          left: '5168.5509999999995',
        },
        size: {
          height: '90.999',
          width: '8.004',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL043',
      name: 'WL043',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3831.0',
          left: '3028.0',
        },
        size: {
          height: '90.999',
          width: '8.004',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL044',
      name: 'WL044',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3831.0',
          left: '3072.6409999999996',
        },
        size: {
          height: '90.999',
          width: '8.004',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL045',
      name: 'WL045',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3599.0',
          left: '3047.0',
        },
        size: {
          height: '239.0',
          width: '15.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL046',
      name: 'WL046',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3913.999',
          left: '3047.0',
        },
        size: {
          height: '370.00100000000003',
          width: '15.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL047',
      name: 'WL047',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2953.0',
          left: '2862.0',
        },
        size: {
          height: '331.999',
          width: '15.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL048',
      name: 'WL048',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3079.001',
          left: '2608.0',
        },
        size: {
          height: '205.998',
          width: '15.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL049',
      name: 'WL049',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2845.0009999999997',
          left: '3046.0',
        },
        size: {
          height: '271.0',
          width: '17.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL050',
      name: 'WL050',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2814.75',
          left: '3786.9990000000003',
        },
        size: {
          height: '285.25',
          width: '17.000999999999998',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL051',
      name: 'WL051',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2800.0',
          left: '4103.0',
        },
        size: {
          height: '299.251',
          width: '17.000999999999998',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL052',
      name: 'WL052',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2813.9980000000005',
          left: '4451.869',
        },
        size: {
          height: '285.25300000000004',
          width: '17.003999999999998',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL053',
      name: 'WL053',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2813.9980000000005',
          left: '4751.928',
        },
        size: {
          height: '285.25300000000004',
          width: '17.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL054',
      name: 'WL054',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2787.999',
          left: '6675.657',
        },
        size: {
          height: '329.00100000000003',
          width: '15.004',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL055',
      name: 'WL055',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1758.0',
          left: '2407.0',
        },
        size: {
          height: '267.0',
          width: '11.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL056',
      name: 'WL056',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1801.9990000000003',
          left: '3049.0',
        },
        size: {
          height: '376.999',
          width: '7.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL057',
      name: 'WL057',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1801.748',
          left: '5142.5019999999995',
        },
        size: {
          height: '377.0',
          width: '15.003',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL058',
      name: 'WL058',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3851.9979999999996',
          left: '7201.993',
        },
        size: {
          height: '50.003',
          width: '8.004999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL059',
      name: 'WL059',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3851.9979999999996',
          left: '7280.991',
        },
        size: {
          height: '50.003',
          width: '8.008',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL060',
      name: 'WL060',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3866.0',
          left: '8235.502',
        },
        size: {
          height: '319.0',
          width: '5.757',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL061',
      name: 'WL061',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3866.0',
          left: '8345.658',
        },
        size: {
          height: '319.0',
          width: '5.756',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL062',
      name: 'WL062',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2752.001',
          left: '5124.9980000000005',
        },
        size: {
          height: '91.0',
          width: '8.004999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL063',
      name: 'WL063',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2752.001',
          left: '5168.251',
        },
        size: {
          height: '91.0',
          width: '8.001000000000001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL064',
      name: 'WL064',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2516.9990000000003',
          left: '8276.996000000001',
        },
        size: {
          height: '267.0',
          width: '15.003',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL065',
      name: 'WL065',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2765.999',
          left: '8550.0',
        },
        size: {
          height: '143.002',
          width: '19.000999999999998',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL066',
      name: 'WL066',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3286.4030000000002',
          left: '8550.0',
        },
        size: {
          height: '95.596',
          width: '19.000999999999998',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL067',
      name: 'WL067',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3380.7639999999997',
          left: '8550.0',
        },
        size: {
          height: '79.237',
          width: '13.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL068',
      name: 'WL068',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2811.9979999999996',
          left: '7760.0',
        },
        size: {
          height: '650.0',
          width: '19.000999999999998',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL069',
      name: 'WL069',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2783.9980000000005',
          left: '8335.001',
        },
        size: {
          height: '69.003',
          width: '12.001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL070',
      name: 'WL070',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2746.001',
          left: '8239.821',
        },
        size: {
          height: '107.001',
          width: '12.001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL071',
      name: 'WL071',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2712.0',
          left: '7437.001',
        },
        size: {
          height: '77.999',
          width: '12.001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL072',
      name: 'WL072',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2761.9990000000003',
          left: '7193.002',
        },
        size: {
          height: '70.00200000000001',
          width: '11.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL073',
      name: 'WL073',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2761.9990000000003',
          left: '7288.082',
        },
        size: {
          height: '70.00200000000001',
          width: '10.999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL074',
      name: 'WL074',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2968.0',
          left: '7417.999',
        },
        size: {
          height: '110.0',
          width: '12.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL075',
      name: 'WL075',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3058.999',
          left: '7219.000999999999',
        },
        size: {
          height: '136.0',
          width: '11.997',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL076',
      name: 'WL076',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3161.001',
          left: '7198.999',
        },
        size: {
          height: '181.998',
          width: '22.003',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL077',
      name: 'WL077',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2935.0009999999997',
          left: '7200.001',
        },
        size: {
          height: '179.998',
          width: '19.999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL078',
      name: 'WL078',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3267.999',
          left: '7579.999',
        },
        size: {
          height: '193.002',
          width: '15.003',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL079',
      name: 'WL079',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3172.999',
          left: '7419.002',
        },
        size: {
          height: '110.0',
          width: '19.999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL080',
      name: 'WL080',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2770.999',
          left: '6155.0019999999995',
        },
        size: {
          height: '51.001000000000005',
          width: '5.997',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL081',
      name: 'WL081',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2770.999',
          left: '6234.91',
        },
        size: {
          height: '51.001000000000005',
          width: '5.997',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL082',
      name: 'WL082',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2752.001',
          left: '3027.998',
        },
        size: {
          height: '93.0',
          width: '8.001000000000001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL083',
      name: 'WL083',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2752.001',
          left: '3072.554',
        },
        size: {
          height: '48.997',
          width: '8.001000000000001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL084',
      name: 'WL084',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2814.75',
          left: '3079.538',
        },
        size: {
          height: '46.25',
          width: '17.000999999999998',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL085',
      name: 'WL085',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1707.0',
          left: '2853.195',
        },
        size: {
          height: '62.001',
          width: '24.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL086',
      name: 'WL086',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1707.0',
          left: '2356.0',
        },
        size: {
          height: '318.001',
          width: '24.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL087',
      name: 'WL087',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '4114.001',
          left: '2355.0',
        },
        size: {
          height: '330.0',
          width: '24.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL088',
      name: 'WL088',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '4273.0',
          left: '2507.0',
        },
        size: {
          height: '170.0',
          width: '24.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL089',
      name: 'WL089',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1283.998',
          left: '1477.0',
        },
        size: {
          height: '2884.002',
          width: '24.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL090',
      name: 'WL090',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1284.0010000000002',
          left: '8197.0',
        },
        size: {
          height: '741.9970000000001',
          width: '23.999000000000002',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL091',
      name: 'WL091',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1766.993',
          left: '8271.75',
        },
        size: {
          height: '42.008',
          width: '6.0040000000000004',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL092',
      name: 'WL092',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1766.996',
          left: '8308.753',
        },
        size: {
          height: '42.004999999999995',
          width: '6.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL093',
      name: 'WL093',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '4114.001',
          left: '2407.0',
        },
        size: {
          height: '170.0',
          width: '11.881',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL094',
      name: 'WL094',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2044.999',
          left: '8227.999',
        },
        size: {
          height: '145.002',
          width: '14.001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL095',
      name: 'WL095',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2387.5860000000002',
          left: '7437.001',
        },
        size: {
          height: '22.416',
          width: '12.001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL096',
      name: 'WL096',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2342.0',
          left: '7228.999',
        },
        size: {
          height: '47.999',
          width: '8.001000000000001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL097',
      name: 'WL097',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2342.0',
          left: '7253.895',
        },
        size: {
          height: '47.999',
          width: '8.004',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL098',
      name: 'WL098',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3853.0',
          left: '4078.997',
        },
        size: {
          height: '47.001',
          width: '8.004',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL099',
      name: 'WL099',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3853.0',
          left: '4118.991',
        },
        size: {
          height: '47.001',
          width: '8.008',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL100',
      name: 'WL100',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3209.9990000000003',
          left: '3364.0',
        },
        size: {
          height: '230',
          width: '13.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL101',
      name: 'WL101',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3209.9990000000003',
          left: '3692.0200000000004',
        },
        size: {
          height: '230',
          width: '13.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL102',
      name: 'WL102',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3426.001',
          left: '6016.001',
        },
        size: {
          height: '35.997',
          width: '13.998',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL103',
      name: 'WL103',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1801.9990000000003',
          left: '3062.998',
        },
        size: {
          height: '376.999',
          width: '14.001999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL104',
      name: 'WL104',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2176.998',
          left: '3042.0',
        },
        size: {
          height: '14.001999999999999',
          width: '81.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL105',
      name: 'WL105',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2176.998',
          left: '2525.0',
        },
        size: {
          height: '14.001999999999999',
          width: '358.587',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL106',
      name: 'WL106',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2176.998',
          left: '2415.06',
        },
        size: {
          height: '14.006',
          width: '9.94',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL107',
      name: 'WL107',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2001.001',
          left: '2356.0',
        },
        size: {
          height: '23.999000000000002',
          width: '62.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL108',
      name: 'WL108',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1706.9360000000001',
          left: '2356.0',
        },
        size: {
          height: '23.999000000000002',
          width: '521.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL109',
      name: 'WL109',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1758.837',
          left: '2407.0',
        },
        size: {
          height: '11.162',
          width: '470.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL110',
      name: 'WL110',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2176.998',
          left: '3740.002',
        },
        size: {
          height: '14.001999999999999',
          width: '80.998',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL111',
      name: 'WL111',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2176.998',
          left: '4439.003000000001',
        },
        size: {
          height: '14.001999999999999',
          width: '74.997',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL112',
      name: 'WL112',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2176.998',
          left: '5127.001',
        },
        size: {
          height: '14.006',
          width: '30.998',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL113',
      name: 'WL113',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2176.995',
          left: '6016.001',
        },
        size: {
          height: '14.006',
          width: '67.998',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL114',
      name: 'WL114',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2176.995',
          left: '6703.0',
        },
        size: {
          height: '14.006',
          width: '375.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL115',
      name: 'WL115',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2171.9990000000003',
          left: '7064.000999999999',
        },
        size: {
          height: '19.000999999999998',
          width: '13.998',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL116',
      name: 'WL116',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2176.995',
          left: '8228.002',
        },
        size: {
          height: '14.006',
          width: '132.99699999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL117',
      name: 'WL117',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1999.999',
          left: '7433.0',
        },
        size: {
          height: '27.002000000000002',
          width: '787.9989999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL118',
      name: 'WL118',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1801.9990000000003',
          left: '8272.001',
        },
        size: {
          height: '7.003',
          width: '43.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL119',
      name: 'WL119',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1801.9990000000003',
          left: '7224.0',
        },
        size: {
          height: '7.003',
          width: '43.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL120',
      name: 'WL120',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1801.9990000000003',
          left: '6176.0',
        },
        size: {
          height: '7.003',
          width: '43.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL121',
      name: 'WL121',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1801.9990000000003',
          left: '5128.25',
        },
        size: {
          height: '7.003',
          width: '43.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL122',
      name: 'WL122',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1801.9990000000003',
          left: '4081.001',
        },
        size: {
          height: '7.003',
          width: '43.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL123',
      name: 'WL123',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1801.9990000000003',
          left: '3033.0',
        },
        size: {
          height: '7.003',
          width: '43.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL124',
      name: 'WL124',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2027.999',
          left: '7185.999000000001',
        },
        size: {
          height: '13.0',
          width: '230.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL125',
      name: 'WL125',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1284.0010000000002',
          left: '8197.0',
        },
        size: {
          height: '21.999',
          width: '375.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL126',
      name: 'WL126',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '1284.0010000000002',
          left: '1477.0',
        },
        size: {
          height: '21.999',
          width: '5903.097',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL127',
      name: 'WL127',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2388.996',
          left: '3345.0',
        },
        size: {
          height: '14.001999999999999',
          width: '41.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL128',
      name: 'WL128',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2388.996',
          left: '3734.446',
        },
        size: {
          height: '14.001999999999999',
          width: '50.553',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL129',
      name: 'WL129',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2388.996',
          left: '4132.732',
        },
        size: {
          height: '14.001999999999999',
          width: '48.266999999999996',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL130',
      name: 'WL130',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2388.996',
          left: '4529.053',
        },
        size: {
          height: '14.001999999999999',
          width: '42.946999999999996',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL131',
      name: 'WL131',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2388.996',
          left: '4920.591',
        },
        size: {
          height: '14.001999999999999',
          width: '25.411',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL132',
      name: 'WL132',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2388.002',
          left: '6016.001',
        },
        size: {
          height: '15.0',
          width: '39.998',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL133',
      name: 'WL133',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2387.998',
          left: '6380.999000000001',
        },
        size: {
          height: '15.0',
          width: '50.003',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL134',
      name: 'WL134',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2387.998',
          left: '6756.0019999999995',
        },
        size: {
          height: '15.0',
          width: '51.999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL135',
      name: 'WL135',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2387.0',
          left: '7132.0',
        },
        size: {
          height: '15.0',
          width: '317.002',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL136',
      name: 'WL136',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2395.001',
          left: '8277.0',
        },
        size: {
          height: '15.999',
          width: '293.003',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL137',
      name: 'WL137',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2767.883',
          left: '8277.0',
        },
        size: {
          height: '15.999',
          width: '293.003',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL138',
      name: 'WL138',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2841.9979999999996',
          left: '8240.0',
        },
        size: {
          height: '11.003',
          width: '107.001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL139',
      name: 'WL139',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2746.001',
          left: '8240.0',
        },
        size: {
          height: '11.003',
          width: '36.999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL140',
      name: 'WL140',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3446.9990000000003',
          left: '7760.0',
        },
        size: {
          height: '15.003',
          width: '42.001999999999995',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL141',
      name: 'WL141',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3446.9990000000003',
          left: '8531.0',
        },
        size: {
          height: '15.0',
          width: '41.004',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL142',
      name: 'WL142',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3755.252',
          left: '8218.002',
        },
        size: {
          height: '15.0',
          width: '124.999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL143',
      name: 'WL143',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3754.998',
          left: '7182.002',
        },
        size: {
          height: '15.003',
          width: '116.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL144',
      name: 'WL144',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3754.998',
          left: '6715.0019999999995',
        },
        size: {
          height: '15.003',
          width: '69.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL145',
      name: 'WL145',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3849.998',
          left: '6233.002',
        },
        size: {
          height: '15.004',
          width: '71.99900000000001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL146',
      name: 'WL146',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3853.0',
          left: '6016.001',
        },
        size: {
          height: '15.004',
          width: '145.997',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL147',
      name: 'WL147',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3849.998',
          left: '6155.888',
        },
        size: {
          height: '9.003',
          width: '77.114',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL148',
      name: 'WL148',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3894.1620000000003',
          left: '6155.888',
        },
        size: {
          height: '9.003',
          width: '77.114',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL149',
      name: 'WL149',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3850.996',
          left: '6700.0019999999995',
        },
        size: {
          height: '15.004',
          width: '15.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL150',
      name: 'WL150',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3894.998',
          left: '7202.001',
        },
        size: {
          height: '8.001000000000001',
          width: '79.114',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL151',
      name: 'WL151',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3852.04',
          left: '7202.001',
        },
        size: {
          height: '8.001000000000001',
          width: '79.114',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL152',
      name: 'WL152',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3831.0',
          left: '5123.999',
        },
        size: {
          height: '8.001000000000001',
          width: '53.001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL153',
      name: 'WL153',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3914.0519999999997',
          left: '5123.999',
        },
        size: {
          height: '8.001000000000001',
          width: '53.001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL154',
      name: 'WL154',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3852.996',
          left: '4440.002',
        },
        size: {
          height: '15.004',
          width: '17.995',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL155',
      name: 'WL155',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3852.996',
          left: '4119.0019999999995',
        },
        size: {
          height: '15.004',
          width: '19.999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL156',
      name: 'WL156',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3852.996',
          left: '4066.001',
        },
        size: {
          height: '15.004',
          width: '20.987000000000002',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL157',
      name: 'WL157',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3891.9990000000003',
          left: '4079.0',
        },
        size: {
          height: '8.001000000000001',
          width: '47.999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL158',
      name: 'WL158',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3853.0',
          left: '3741.0',
        },
        size: {
          height: '15.004',
          width: '24.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL159',
      name: 'WL159',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3852.996',
          left: '3080.0',
        },
        size: {
          height: '15.004',
          width: '42.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL160',
      name: 'WL160',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3599.0',
          left: '2976.0',
        },
        size: {
          height: '15.004',
          width: '86.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL161',
      name: 'WL161',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3915.0009999999997',
          left: '3028.0',
        },
        size: {
          height: '8.001000000000001',
          width: '53.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL162',
      name: 'WL162',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3830.8730000000005',
          left: '3028.0',
        },
        size: {
          height: '8.001000000000001',
          width: '53.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL163',
      name: 'WL163',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3866.0',
          left: '8235.001',
        },
        size: {
          height: '8.001000000000001',
          width: '15.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL164',
      name: 'WL164',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '4176.79',
          left: '8336.173999999999',
        },
        size: {
          height: '8.001000000000001',
          width: '15.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL165',
      name: 'WL165',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '4176.999',
          left: '8235.502',
        },
        size: {
          height: '8.001000000000001',
          width: '15.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL166',
      name: 'WL166',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '4420.251',
          left: '2355.0',
        },
        size: {
          height: '24.0',
          width: '176.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL167',
      name: 'WL167',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '4273.0',
          left: '2407.0',
        },
        size: {
          height: '11.0',
          width: '122.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL168',
      name: 'WL168',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '4114.001',
          left: '2355.0',
        },
        size: {
          height: '23.999000000000002',
          width: '64.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL169',
      name: 'WL169',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '4144.0',
          left: '1477.0',
        },
        size: {
          height: '24.0',
          width: '878.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL170',
      name: 'WL170',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3446.9990000000003',
          left: '6016.001',
        },
        size: {
          height: '15.007',
          width: '570.001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL171',
      name: 'WL171',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3446.995',
          left: '6690.001',
        },
        size: {
          height: '15.004',
          width: '905.0020000000001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL172',
      name: 'WL172',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3161.001',
          left: '6690.001',
        },
        size: {
          height: '13.0',
          width: '529.001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL173',
      name: 'WL173',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3103.008',
          left: '6692.59',
        },
        size: {
          height: '13.0',
          width: '529.001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL174',
      name: 'WL174',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2788.5',
          left: '7298.728999999999',
        },
        size: {
          height: '14.001999999999999',
          width: '296.273',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL175',
      name: 'WL175',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2767.997',
          left: '7437.001',
        },
        size: {
          height: '12.004999999999999',
          width: '158.002',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL176',
      name: 'WL176',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3055.9930000000004',
          left: '7219.000999999999',
        },
        size: {
          height: '13.007',
          width: '219.999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL177',
      name: 'WL177',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3181.494',
          left: '7219.000999999999',
        },
        size: {
          height: '13.007',
          width: '219.999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL178',
      name: 'WL178',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3267.9919999999997',
          left: '7419.002',
        },
        size: {
          height: '14.009',
          width: '53.998999999999995',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL179',
      name: 'WL179',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3267.9919999999997',
          left: '7567.465',
        },
        size: {
          height: '14.009',
          width: '27.538',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL180',
      name: 'WL180',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2968.251',
          left: '7419.002',
        },
        size: {
          height: '14.009',
          width: '53.998999999999995',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL181',
      name: 'WL181',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2968.251',
          left: '7567.465',
        },
        size: {
          height: '14.009',
          width: '27.538',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL182',
      name: 'WL182',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2761.9990000000003',
          left: '7193.002',
        },
        size: {
          height: '12.001999999999999',
          width: '105.999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL183',
      name: 'WL183',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2820.02',
          left: '7193.002',
        },
        size: {
          height: '12.001',
          width: '105.999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL184',
      name: 'WL184',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2342.0',
          left: '7228.999',
        },
        size: {
          height: '8.001000000000001',
          width: '33.001999999999995',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL185',
      name: 'WL185',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2788.5',
          left: '6030.003000000001',
        },
        size: {
          height: '15.0',
          width: '124.996',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL186',
      name: 'WL186',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2788.5',
          left: '6240.854',
        },
        size: {
          height: '15.0',
          width: '170.145',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL187',
      name: 'WL187',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2787.7480000000005',
          left: '6410.999000000001',
        },
        size: {
          height: '15.251',
          width: '265.14099999999996',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL188',
      name: 'WL188',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3101.749',
          left: '6410.999000000001',
        },
        size: {
          height: '15.251',
          width: '265.14099999999996',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL189',
      name: 'WL189',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2814.75',
          left: '6155.888',
        },
        size: {
          height: '8.252',
          width: '79.114',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL190',
      name: 'WL190',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2771.14',
          left: '6155.888',
        },
        size: {
          height: '8.251',
          width: '79.114',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL191',
      name: 'WL191',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2751.9979999999996',
          left: '5125.001',
        },
        size: {
          height: '8.251',
          width: '51.001000000000005',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL192',
      name: 'WL192',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2834.7879999999996',
          left: '5125.001',
        },
        size: {
          height: '8.251',
          width: '51.001000000000005',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL193',
      name: 'WL193',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2787.7480000000005',
          left: '6702.979',
        },
        size: {
          height: '15.251',
          width: '490.02299999999997',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL194',
      name: 'WL194',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2715.002',
          left: '7582.998',
        },
        size: {
          height: '253.99699999999999',
          width: '12.001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL195',
      name: 'WL195',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3098.496',
          left: '4228.0',
        },
        size: {
          height: '15.251',
          width: '697.819',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL196',
      name: 'WL196',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2797.951',
          left: '4118.0',
        },
        size: {
          height: '15.25',
          width: '828.001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL197',
      name: 'WL197',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2799.951',
          left: '3080.0',
        },
        size: {
          height: '18.048',
          width: '1022.3789999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL198',
      name: 'WL198',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3098.951',
          left: '3174.0',
        },
        size: {
          height: '18.048',
          width: '823.001',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL199',
      name: 'WL199',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3098.951',
          left: '3046.0',
        },
        size: {
          height: '18.048',
          width: '32.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL200',
      name: 'WL200',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3098.951',
          left: '4092.0',
        },
        size: {
          height: '18.048',
          width: '27.002000000000002',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL201',
      name: 'WL201',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3099.001',
          left: '4119.0019999999995',
        },
        size: {
          height: '13.998',
          width: '6.999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL202',
      name: 'WL202',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3451.2419999999997',
          left: '4281.001',
        },
        size: {
          height: '15.003',
          width: '21.000999999999998',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL203',
      name: 'WL203',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3451.2419999999997',
          left: '4603.528',
        },
        size: {
          height: '15.003',
          width: '24.472',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL204',
      name: 'WL204',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3451.2419999999997',
          left: '4928.973',
        },
        size: {
          height: '15.003',
          width: '17.029',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL205',
      name: 'WL205',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2844.916',
          left: '3046.0',
        },
        size: {
          height: '18.048',
          width: '32.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL206',
      name: 'WL206',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2844.916',
          left: '3028.0',
        },
        size: {
          height: '9.084',
          width: '18.439',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL207',
      name: 'WL207',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2752.001',
          left: '3027.561',
        },
        size: {
          height: '9.084',
          width: '52.854',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL208',
      name: 'WL208',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2776.9139999999998',
          left: '4079.9990000000003',
        },
        size: {
          height: '8.086',
          width: '45.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL209',
      name: 'WL209',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2776.9990000000003',
          left: '4077.861',
        },
        size: {
          height: '21.000999999999998',
          width: '8.004999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL210',
      name: 'WL210',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2776.9990000000003',
          left: '4117.108',
        },
        size: {
          height: '21.000999999999998',
          width: '8.004999999999999',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL211',
      name: 'WL211',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3271.749',
          left: '2416.0',
        },
        size: {
          height: '14.001999999999999',
          width: '461.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL212',
      name: 'WL212',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '3220.868',
          left: '2608.0',
        },
        size: {
          height: '20.133000000000003',
          width: '269.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'WL213',
      name: 'WL213',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '2957.523',
          left: '2526.0',
        },
        size: {
          height: '14.001999999999999',
          width: '351.0',
        },
        type: 'wall',
        info: [],
      },
    },
    {
      id: 'AR001',
      name: 'AR001',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '1771.0',
          left: '3079.0',
        },
        size: {
          height: '405.99800000000005',
          width: '664.999',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR002',
      name: 'AR002',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '1771.0',
          left: '2419.594',
        },
        size: {
          height: '404.00100000000003',
          width: '626.406',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR003',
      name: 'AR003',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '1771.0',
          left: '3762.082',
        },
        size: {
          height: '405.99800000000005',
          width: '680.9180000000001',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR004',
      name: 'AR004',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '1771.0',
          left: '4460.124',
        },
        size: {
          height: '405.99800000000005',
          width: '680.9180000000001',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR005',
      name: 'AR005',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '1771.0',
          left: '6031.491999999999',
        },
        size: {
          height: '405.99800000000005',
          width: '681.51',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR006',
      name: 'AR006',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '1771.0',
          left: '6730.188',
        },
        size: {
          height: '405.0',
          width: '332.81100000000004',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR007',
      name: 'AR007',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '1771.0',
          left: '7078.899',
        },
        size: {
          height: '254.999',
          width: '332.81',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR008',
      name: 'AR008',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '2192.5',
          left: '2419.594',
        },
        size: {
          height: '764.5010000000001',
          width: '443.406',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR009',
      name: 'AR009',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '2974.001',
          left: '2402.594',
        },
        size: {
          height: '295.998',
          width: '204.40699999999998',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR010',
      name: 'AR010',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '2974.001',
          left: '2624.0',
        },
        size: {
          height: '245.99900000000002',
          width: '237.382',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR011',
      name: 'AR011',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '2403.323',
          left: '3360.0',
        },
        size: {
          height: '395.676',
          width: '383.999',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR012',
      name: 'AR012',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '2403.323',
          left: '3756.505',
        },
        size: {
          height: '395.676',
          width: '380.496',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR013',
      name: 'AR013',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '2403.323',
          left: '4153.108',
        },
        size: {
          height: '395.676',
          width: '380.496',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR014',
      name: 'AR014',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '2403.323',
          left: '4549.712',
        },
        size: {
          height: '395.676',
          width: '380.496',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR015',
      name: 'AR015',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '2401.75',
          left: '6031.361000000001',
        },
        size: {
          height: '385.741',
          width: '359.639',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR016',
      name: 'AR016',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '2401.75',
          left: '6408.1449999999995',
        },
        size: {
          height: '385.741',
          width: '355.854',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR017',
      name: 'AR017',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '2401.75',
          left: '6780.516',
        },
        size: {
          height: '385.741',
          width: '356.485',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR018',
      name: 'AR018',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '2402.9990000000003',
          left: '7154.040999999999',
        },
        size: {
          height: '384.493',
          width: '281.961',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR019',
      name: 'AR019',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '2804.35',
          left: '6031.361000000001',
        },
        size: {
          height: '641.6500000000001',
          width: '378.64',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR020',
      name: 'AR020',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '3119.0',
          left: '6427.325',
        },
        size: {
          height: '327.0',
          width: '260.424',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR021',
      name: 'AR021',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '3176.0019999999995',
          left: '6703.688',
        },
        size: {
          height: '269.0',
          width: '161.311',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR022',
      name: 'AR022',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '2804.001',
          left: '6704.691000000001',
        },
        size: {
          height: '297.0',
          width: '159.311',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR023',
      name: 'AR023',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '2942.999',
          left: '6870.055',
        },
        size: {
          height: '158.002',
          width: '105.445',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR024',
      name: 'AR024',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '3175.928',
          left: '6870.055',
        },
        size: {
          height: '157.071',
          width: '105.445',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR025',
      name: 'AR025',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '3175.928',
          left: '6981.339',
        },
        size: {
          height: '157.071',
          width: '105.445',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR026',
      name: 'AR026',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '3175.928',
          left: '7092.421',
        },
        size: {
          height: '157.071',
          width: '104.582',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR027',
      name: 'AR027',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '3338.251',
          left: '6870.055',
        },
        size: {
          height: '106.78200000000001',
          width: '327.946',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR028',
      name: 'AR028',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '3114.9990000000003',
          left: '4622.0',
        },
        size: {
          height: '335.002',
          width: '308.20799999999997',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR029',
      name: 'AR029',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '3114.9990000000003',
          left: '4297.042',
        },
        size: {
          height: '335.002',
          width: '308.20799999999997',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR030',
      name: 'AR030',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '2814.25',
          left: '4120.0',
        },
        size: {
          height: '282.752',
          width: '331.999',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR031',
      name: 'AR031',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '2819.001',
          left: '3806.0',
        },
        size: {
          height: '278.998',
          width: '296.002',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR032',
      name: 'AR032',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '3868.0',
          left: '3063.0',
        },
        size: {
          height: '414.25',
          width: '681.249',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR033',
      name: 'AR033',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '3868.0',
          left: '3761.8599999999997',
        },
        size: {
          height: '414.25',
          width: '681.25',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR034',
      name: 'AR034',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '3868.0',
          left: '6031.8589999999995',
        },
        size: {
          height: '414.25',
          width: '681.249',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR035',
      name: 'AR035',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '3771.0',
          left: '6730.0199999999995',
        },
        size: {
          height: '511.25',
          width: '506.981',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'AR036',
      name: 'AR036',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '3771.0',
          left: '7254.223',
        },
        size: {
          height: '511.25',
          width: '994.777',
        },
        type: 'area',
        info: [],
      },
    },
    {
      id: 'RN001',
      name: 'RN001',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '1925.3729999999998',
          left: '3262.481',
        },
        size: {
          height: '46.207',
          width: '258.08',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN002',
      name: 'RN002',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '1924.3709999999999',
          left: '3974.864',
        },
        size: {
          height: '46.205999999999996',
          width: '258.082',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN003',
      name: 'RN003',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '1923.118',
          left: '4658.8189999999995',
        },
        size: {
          height: '46.207',
          width: '258.082',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN004',
      name: 'RN004',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '2540.794',
          left: '4237.602',
        },
        size: {
          height: '46.205999999999996',
          width: '154.397',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN005',
      name: 'RN005',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '2540.794',
          left: '3856.602',
        },
        size: {
          height: '46.205999999999996',
          width: '154.397',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN006',
      name: 'RN006',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '2539.795',
          left: '3455.603',
        },
        size: {
          height: '46.203',
          width: '154.397',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN007',
      name: 'RN007',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '1804.4579999999999',
          left: '2589.791',
        },
        size: {
          height: '46.205999999999996',
          width: '258.08',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN008',
      name: 'RN008',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '2486.346',
          left: '2505.969',
        },
        size: {
          height: '46.207',
          width: '258.08',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN009',
      name: 'RN009',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '3113.5460000000003',
          left: '2632.0',
        },
        size: {
          height: '46.205999999999996',
          width: '210.0',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN010',
      name: 'RN010',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '3718.422',
          left: '2548.752',
        },
        size: {
          height: '46.207',
          width: '258.08',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN011',
      name: 'RN011',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '3623.26',
          left: '3599.688',
        },
        size: {
          height: '46.207',
          width: '258.082',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN012',
      name: 'RN012',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '4026.112',
          left: '3291.459',
        },
        size: {
          height: '46.203',
          width: '258.082',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN013',
      name: 'RN013',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '4023.631',
          left: '3959.5249999999996',
        },
        size: {
          height: '46.207',
          width: '258.082',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN014',
      name: 'RN014',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '4041.4179999999997',
          left: '5380.535',
        },
        size: {
          height: '46.207',
          width: '258.082',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN015',
      name: 'RN015',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '4034.423',
          left: '6224.528',
        },
        size: {
          height: '46.207',
          width: '258.081',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN016',
      name: 'RN016',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '4036.6059999999998',
          left: '6852.501',
        },
        size: {
          height: '46.207',
          width: '258.082',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN017',
      name: 'RN017',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '4059.7699999999995',
          left: '7643.975',
        },
        size: {
          height: '46.207',
          width: '258.078',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN018',
      name: 'RN018',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '2971.511',
          left: '8339.998',
        },
        size: {
          height: '46.205999999999996',
          width: '151.00400000000002',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN019',
      name: 'RN019',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '2339.922',
          left: '7881.0',
        },
        size: {
          height: '46.207',
          width: '144.001',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN020',
      name: 'RN020',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '3287.96',
          left: '6908.169',
        },
        size: {
          height: '82.65599999999999',
          width: '258.082',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN021',
      name: 'RN021',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '3075.22',
          left: '7207.0560000000005',
        },
        size: {
          height: '46.207',
          width: '258.078',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN022',
      name: 'RN022',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '2854.709',
          left: '6959.198',
        },
        size: {
          height: '81.03999999999999',
          width: '258.082',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN023',
      name: 'RN023',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '3225.182',
          left: '6431.721',
        },
        size: {
          height: '46.207',
          width: '258.082',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN024',
      name: 'RN024',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '2972.6389999999997',
          left: '6082.002',
        },
        size: {
          height: '46.207',
          width: '271.946',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN025',
      name: 'RN025',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '2549.433',
          left: '6118.5',
        },
        size: {
          height: '46.207',
          width: '152.002',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN026',
      name: 'RN026',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '2548.957',
          left: '6543.002',
        },
        size: {
          height: '46.207',
          width: '161.999',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN027',
      name: 'RN027',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '2549.433',
          left: '6863.5',
        },
        size: {
          height: '46.207',
          width: '169.002',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN028',
      name: 'RN028',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '2550.036',
          left: '7198.999',
        },
        size: {
          height: '46.207',
          width: '186.002',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN029',
      name: 'RN029',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '2143.0119999999997',
          left: '7150.0019999999995',
        },
        size: {
          height: '46.203',
          width: '191.999',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN030',
      name: 'RN030',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '1861.513',
          left: '7129.999',
        },
        size: {
          height: '46.205999999999996',
          width: '189.00099999999998',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN031',
      name: 'RN031',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '1861.3290000000002',
          left: '6775.115',
        },
        size: {
          height: '46.203',
          width: '258.082',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN032',
      name: 'RN032',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '1861.199',
          left: '6249.638',
        },
        size: {
          height: '46.203',
          width: '258.078',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN033',
      name: 'RN033',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '1925.0649999999998',
          left: '5383.53',
        },
        size: {
          height: '46.207',
          width: '258.079',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN034',
      name: 'RN034',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '2897.752',
          left: '5389.834',
        },
        size: {
          height: '46.203',
          width: '258.082',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN035',
      name: 'RN035',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '2538.335',
          left: '4662.001',
        },
        size: {
          height: '46.207',
          width: '153.99800000000002',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN036',
      name: 'RN036',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '2898.372',
          left: '4222.62',
        },
        size: {
          height: '46.207',
          width: '88.727',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN037',
      name: 'RN037',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '2913.698',
          left: '3878.0009999999997',
        },
        size: {
          height: '46.205999999999996',
          width: '177.99800000000002',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN038',
      name: 'RN038',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '2893.3280000000004',
          left: '3100.0',
        },
        size: {
          height: '46.207',
          width: '180.0',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN039',
      name: 'RN039',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '3215.499',
          left: '4330.094',
        },
        size: {
          height: '46.205999999999996',
          width: '258.082',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RN040',
      name: 'RN040',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '3217.372',
          left: '4654.388',
        },
        size: {
          height: '46.205999999999996',
          width: '258.082',
        },
        type: 'roomName',
        info: [],
      },
    },
    {
      id: 'RNA001',
      name: 'RNA001',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '1975.001',
          left: '3356.021',
        },
        size: {
          height: '47.999',
          width: '82.979',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA002',
      name: 'RNA002',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '1975.001',
          left: '4067.733',
        },
        size: {
          height: '47.999',
          width: '83.26599999999999',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA003',
      name: 'RNA003',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '1975.001',
          left: '4748.001',
        },
        size: {
          height: '47.999',
          width: '84.0',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA004',
      name: 'RNA004',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '2589.001',
          left: '4699.0',
        },
        size: {
          height: '49.001000000000005',
          width: '84.0',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA005',
      name: 'RNA005',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '2589.9990000000003',
          left: '4276.602',
        },
        size: {
          height: '49.001000000000005',
          width: '83.4',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA006',
      name: 'RNA006',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '2592.3700000000003',
          left: '3892.099',
        },
        size: {
          height: '45.077999999999996',
          width: '82.271',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA007',
      name: 'RNA007',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '2589.749',
          left: '3491.5',
        },
        size: {
          height: '50.25',
          width: '84.499',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA008',
      name: 'RNA008',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '2536.948',
          left: '2598.607',
        },
        size: {
          height: '48.052',
          width: '83.39399999999999',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA009',
      name: 'RNA009',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '1852.654',
          left: '2671.003',
        },
        size: {
          height: '50.345000000000006',
          width: '84.99700000000001',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA010',
      name: 'RNA010',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '3162.0',
          left: '2693.0',
        },
        size: {
          height: '49.001000000000005',
          width: '84.0',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA011',
      name: 'RNA011',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '3766.001',
          left: '2636.0',
        },
        size: {
          height: '48.997',
          width: '85.0',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA012',
      name: 'RNA012',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '4073.0',
          left: '3376.0',
        },
        size: {
          height: '50.001',
          width: '84.0',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA013',
      name: 'RNA013',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '4073.7509999999997',
          left: '4048.7490000000003',
        },
        size: {
          height: '48.498999999999995',
          width: '83.25200000000001',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA014',
      name: 'RNA014',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '4091.499',
          left: '5475.09',
        },
        size: {
          height: '48.501000000000005',
          width: '83.911',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA015',
      name: 'RNA015',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '4084.75',
          left: '6313.999',
        },
        size: {
          height: '48.25',
          width: '84.0',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA016',
      name: 'RNA016',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '4084.75',
          left: '6939.252',
        },
        size: {
          height: '48.25',
          width: '83.749',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA017',
      name: 'RNA017',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '4109.588',
          left: '7733.799',
        },
        size: {
          height: '48.413000000000004',
          width: '84.202',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA018',
      name: 'RNA018',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '3019.474',
          left: '8373.002',
        },
        size: {
          height: '49.526',
          width: '83.047',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA019',
      name: 'RNA019',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '2389.0',
          left: '7911.0',
        },
        size: {
          height: '47.999',
          width: '82.271',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA020',
      name: 'RNA020',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '2597.9219999999996',
          left: '7253.728999999999',
        },
        size: {
          height: '48.076',
          width: '84.27099999999999',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA021',
      name: 'RNA021',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '2598.0',
          left: '6903.0',
        },
        size: {
          height: '49.001000000000005',
          width: '88.0',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA022',
      name: 'RNA022',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '2947.924',
          left: '7034.999',
        },
        size: {
          height: '49.074999999999996',
          width: '85.002',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA023',
      name: 'RNA023',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '3129.9990000000003',
          left: '7297.001',
        },
        size: {
          height: '49.001000000000005',
          width: '82.271',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA024',
      name: 'RNA024',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '3382.0',
          left: '6987.001',
        },
        size: {
          height: '49.0',
          width: '84.998',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA025',
      name: 'RNA025',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '2598.9980000000005',
          left: '6583.0',
        },
        size: {
          height: '47.001',
          width: '84.0',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA026',
      name: 'RNA026',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '2598.0',
          left: '6151.0019999999995',
        },
        size: {
          height: '47.999',
          width: '82.99799999999999',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA027',
      name: 'RNA027',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '3022.0',
          left: '6181.0019999999995',
        },
        size: {
          height: '49.001000000000005',
          width: '82.268',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA028',
      name: 'RNA028',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '3272.0',
          left: '6519.002',
        },
        size: {
          height: '49.001000000000005',
          width: '82.99799999999999',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA029',
      name: 'RNA029',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '2948.499',
          left: '5474.7519999999995',
        },
        size: {
          height: '49.252',
          width: '84.25',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA030',
      name: 'RNA030',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '3265.999',
          left: '4744.001',
        },
        size: {
          height: '48.003',
          width: '84.0',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA031',
      name: 'RNA031',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '3265.999',
          left: '4408.999',
        },
        size: {
          height: '49.001000000000005',
          width: '84.0',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA032',
      name: 'RNA032',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '2948.7490000000003',
          left: '4227.499',
        },
        size: {
          height: '49.251',
          width: '82.271',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA033',
      name: 'RNA033',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '2968.999',
          left: '3922.0',
        },
        size: {
          height: '50.003',
          width: '83.001',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA034',
      name: 'RNA034',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '3672.0',
          left: '3668.9980000000005',
        },
        size: {
          height: '49.999',
          width: '85.002',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA035',
      name: 'RNA035',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '2953.0',
          left: '3144.0',
        },
        size: {
          height: '49.001000000000005',
          width: '84.0',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA036',
      name: 'RNA036',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '2302.002',
          left: '3149.0',
        },
        size: {
          height: '48.997',
          width: '85.0',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA037',
      name: 'RNA037',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '1975.001',
          left: '5475.0019999999995',
        },
        size: {
          height: '47.999',
          width: '83.99900000000001',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA038',
      name: 'RNA038',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '1909.0',
          left: '6337.0',
        },
        size: {
          height: '49.001000000000005',
          width: '84.0',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA039',
      name: 'RNA039',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '1909.2509999999997',
          left: '6863.5',
        },
        size: {
          height: '48.75',
          width: '83.499',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA040',
      name: 'RNA040',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '1909.0',
          left: '7181.0',
        },
        size: {
          height: '49.001000000000005',
          width: '83.00200000000001',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'RNA041',
      name: 'RNA041',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '2191.501',
          left: '7200.999000000001',
        },
        size: {
          height: '47.498',
          width: '84.003',
        },
        type: 'roomNumber',
        info: [],
      },
    },
    {
      id: 'WT001',
      name: 'WT001',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2042.3890000000001',
          left: '3078.1330000000003',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT002',
      name: 'WT002',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2084.966',
          left: '3798.461',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT003',
      name: 'WT003',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2077.579',
          left: '4474.101',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT004',
      name: 'WT004',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2090.998',
          left: '5175.0',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT005',
      name: 'WT005',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2507.4629999999997',
          left: '4837.921',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT006',
      name: 'WT006',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2691.415',
          left: '4843.241',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT007',
      name: 'WT007',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2516.2960000000003',
          left: '4430.882',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT008',
      name: 'WT008',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2518.364',
          left: '4027.548',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT009',
      name: 'WT009',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2418.1890000000003',
          left: '3908.976',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT010',
      name: 'WT010',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2519.179',
          left: '3642.808',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT011',
      name: 'WT011',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2521.43',
          left: '3240.602',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT012',
      name: 'WT012',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2301.088',
          left: '4168.0380000000005',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT013',
      name: 'WT013',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2090.518',
          left: '2536.016',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT014',
      name: 'WT014',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2659.934',
          left: '2961.1279999999997',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT015',
      name: 'WT015',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2985.128',
          left: '2767.407',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT017',
      name: 'WT017',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3761.7790000000005',
          left: '3083.161',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT018',
      name: 'WT018',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3919.492',
          left: '3108.5739999999996',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT019',
      name: 'WT019',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3764.785',
          left: '3712.393',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT020',
      name: 'WT020',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3923.1670000000004',
          left: '3808.138',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT021',
      name: 'WT021',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3888.8279999999995',
          left: '4120.624',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT022',
      name: 'WT022',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '4199.539',
          left: '4488.046',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT023',
      name: 'WT023',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3774.613',
          left: '5184.058999999999',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT024',
      name: 'WT024',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3903.3909999999996',
          left: '6050.478000000001',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT025',
      name: 'WT025',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3921.302',
          left: '6184.371',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT026',
      name: 'WT026',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3756.547',
          left: '6182.794',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT027',
      name: 'WT027',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '4187.458',
          left: '6610.0509999999995',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT028',
      name: 'WT028',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '4196.787',
          left: '7137.822999999999',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT029',
      name: 'WT029',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3892.0350000000003',
          left: '7140.421',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT030',
      name: 'WT030',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3774.613',
          left: '8129.214',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT031',
      name: 'WT031',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3858.299',
          left: '8141.486999999999',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT032',
      name: 'WT032',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '4181.322',
          left: '8147.625',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT033',
      name: 'WT033',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '4181.0689999999995',
          left: '8351.813',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT034',
      name: 'WT034',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3843.807',
          left: '8379.153',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT035',
      name: 'WT035',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3774.0550000000003',
          left: '8361.857',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT036',
      name: 'WT036',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3650.7029999999995',
          left: '8288.771',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT037',
      name: 'WT037',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3317.826',
          left: '8454.112000000001',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT038',
      name: 'WT038',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3345.9840000000004',
          left: '7798.231',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT039',
      name: 'WT039',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3082.477',
          left: '7637.142',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT040',
      name: 'WT040',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2201.051',
          left: '8291.461000000001',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT041',
      name: 'WT041',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '1811.457',
          left: '8293.499',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT042',
      name: 'WT042',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '1787.5700000000002',
          left: '7131.155',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT043',
      name: 'WT043',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2085.83',
          left: '7355.05',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT044',
      name: 'WT044',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '1986.445',
          left: '6956.161',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT045',
      name: 'WT045',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2076.531',
          left: '6747.627',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT046',
      name: 'WT046',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2630.5009999999997',
          left: '7345.028',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT047',
      name: 'WT047',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2680.648',
          left: '7185.47',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT048',
      name: 'WT048',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2294.099',
          left: '7156.4580000000005',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT049',
      name: 'WT049',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2298.2980000000002',
          left: '6786.013000000001',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT050',
      name: 'WT050',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2675.11',
          left: '6616.968000000001',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT051',
      name: 'WT051',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2504.909',
          left: '6296.735',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT052',
      name: 'WT052',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2686.23',
          left: '6038.984',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT053',
      name: 'WT053',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '1767.924',
          left: '6220.394',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT054',
      name: 'WT054',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '1799.54',
          left: '6054.418',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT055',
      name: 'WT055',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '1792.665',
          left: '5175.447',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT056',
      name: 'WT056',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '1789.433',
          left: '4136.401',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT057',
      name: 'WT057',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '1792.8229999999999',
          left: '2935.258',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT058',
      name: 'WT058',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2980.612',
          left: '4857.376',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT059',
      name: 'WT059',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3112.473',
          left: '4984.983',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT060',
      name: 'WT060',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2821.107',
          left: '5192.339',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT061',
      name: 'WT061',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3337.8379999999997',
          left: '4634.714',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT062',
      name: 'WT062',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2976.774',
          left: '4343.616',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT063',
      name: 'WT063',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2844.341',
          left: '4338.617',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT064',
      name: 'WT064',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3226.639',
          left: '4168.451',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT067',
      name: 'WT067',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2216.8379999999997',
          left: '4415.674',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT068',
      name: 'WT068',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2096.922',
          left: '2957.46',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT069',
      name: 'WT069',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '2820.0950000000003',
          left: '6246.82',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT070',
      name: 'WT070',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3335.531',
          left: '6320.339',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT071',
      name: 'WT071',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3649.267',
          left: '7241.3009999999995',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WT072',
      name: 'WT072',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '3475.6760000000004',
          left: '4529.317',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'wallTag',
        info: [],
      },
    },
    {
      id: 'WE001',
      name: 'WE001',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '2202.466',
          left: '3358.66',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE002',
      name: 'WE002',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '2307.699',
          left: '3494.1090000000004',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE003',
      name: 'WE003',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '2302.242',
          left: '3903.2389999999996',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE004',
      name: 'WE004',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '2194.8419999999996',
          left: '4106.062',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE005',
      name: 'WE005',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '2224.687',
          left: '2953.793',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE006',
      name: 'WE006',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '2685.217',
          left: '2882.4759999999997',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE007',
      name: 'WE007',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '3772.164',
          left: '3359.661',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE008',
      name: 'WE008',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '3774.221',
          left: '3860.56',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE009',
      name: 'WE009',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '3771.83',
          left: '4211.221',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE010',
      name: 'WE010',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '3457.099',
          left: '4386.912',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE011',
      name: 'WE011',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '3452.759',
          left: '4712.988',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE012',
      name: 'WE012',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '2302.478',
          left: '4289.887',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE013',
      name: 'WE013',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '2299.984',
          left: '4713.101',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE014',
      name: 'WE014',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '2188.435',
          left: '4792.666',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE015',
      name: 'WE015',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '2302.87',
          left: '6093.975',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE016',
      name: 'WE016',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '2188.3469999999998',
          left: '6327.236000000001',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE017',
      name: 'WE017',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '2302.647',
          left: '6576.149',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE018',
      name: 'WE018',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '2302.87',
          left: '6900.112',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE019',
      name: 'WE019',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '2540.091',
          left: '7448.4580000000005',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE020',
      name: 'WE020',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '2593.2799999999997',
          left: '7958.624',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE021',
      name: 'WE021',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '2179.422',
          left: '8615.43',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE022',
      name: 'WE022',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '3470.734',
          left: '7978.546',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE023',
      name: 'WE023',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '3671.263',
          left: '7827.846',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE024',
      name: 'WE024',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '3044.3450000000003',
          left: '5938.679',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE025',
      name: 'WE025',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '3772.6229999999996',
          left: '6353.4220000000005',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: 'WE026',
      name: 'WE026',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '3679.5389999999998',
          left: '6890.364',
        },
        size: {
          height: '80',
          width: '80',
        },
        type: 'windowElevation',
        info: [],
      },
    },
    {
      id: '12',
      name: '12',
      parentId: 'Grids',
      type: 'element',
      properties: {
        coords: {
          top: '948.0',
          left: '2006.5',
        },
        size: {
          height: '3771',
          width: '1.0',
        },
        type: 'grid',
        info: [],
      },
    },
    {
      id: '11',
      name: '11',
      parentId: 'Grids',
      type: 'element',
      properties: {
        coords: {
          top: '948.0',
          left: '3054.25',
        },
        size: {
          height: '3771',
          width: '1.0',
        },
        type: 'grid',
        info: [],
      },
    },
    {
      id: '10',
      name: '10',
      parentId: 'Grids',
      type: 'element',
      properties: {
        coords: {
          top: '948.0',
          left: '4102.002',
        },
        size: {
          height: '3771',
          width: '1.0',
        },
        type: 'grid',
        info: [],
      },
    },
    {
      id: '9',
      name: '9',
      parentId: 'Grids',
      type: 'element',
      properties: {
        coords: {
          top: '948.0',
          left: '5149.7519999999995',
        },
        size: {
          height: '3771',
          width: '1.0',
        },
        type: 'grid',
        info: [],
      },
    },
    {
      id: '8',
      name: '8',
      parentId: 'Grids',
      type: 'element',
      properties: {
        coords: {
          top: '948.0',
          left: '6197.5019999999995',
        },
        size: {
          height: '3771',
          width: '1.0',
        },
        type: 'grid',
        info: [],
      },
    },
    {
      id: '7',
      name: '7',
      parentId: 'Grids',
      type: 'element',
      properties: {
        coords: {
          top: '948.0',
          left: '7245.252',
        },
        size: {
          height: '3771',
          width: '1.0',
        },
        type: 'grid',
        info: [],
      },
    },
    {
      id: '6',
      name: '6',
      parentId: 'Grids',
      type: 'element',
      properties: {
        coords: {
          top: '948.0',
          left: '8293.002',
        },
        size: {
          height: '3771',
          width: '1.0',
        },
        type: 'grid',
        info: [],
      },
    },
    {
      id: 'A',
      name: 'A',
      parentId: 'Grids',
      type: 'element',
      properties: {
        coords: {
          top: '1399.998',
          left: '1162.5',
        },
        size: {
          height: '1',
          width: '7673.5',
        },
        type: 'grid',
        info: [],
      },
    },
    {
      id: 'A.5',
      name: 'A.5',
      parentId: 'Grids',
      type: 'element',
      properties: {
        coords: {
          top: '1971.2469999999998',
          left: '1162.5',
        },
        size: {
          height: '1',
          width: '7673.5',
        },
        type: 'grid',
        info: [],
      },
    },
    {
      id: 'B',
      name: 'B',
      parentId: 'Grids',
      type: 'element',
      properties: {
        coords: {
          top: '2796.9979999999996',
          left: '1162.5',
        },
        size: {
          height: '1',
          width: '7673.5',
        },
        type: 'grid',
        info: [],
      },
    },
    {
      id: 'B.5',
      name: 'B.5',
      parentId: 'Grids',
      type: 'element',
      properties: {
        coords: {
          top: '3320.499',
          left: '1162.5',
        },
        size: {
          height: '1',
          width: '7673.5',
        },
        type: 'grid',
        info: [],
      },
    },
    {
      id: 'C',
      name: 'C',
      parentId: 'Grids',
      type: 'element',
      properties: {
        coords: {
          top: '3876.2509999999997',
          left: '1162.5',
        },
        size: {
          height: '1',
          width: '7673.5',
        },
        type: 'grid',
        info: [],
      },
    },
    {
      id: 'TX001',
      name: 'TX001',
      parentId: 'Texts',
      type: 'element',
      properties: {
        coords: {
          top: '7268.371000000001',
          left: '9925.413',
        },
        size: {
          height: '210.747',
          width: '670.56',
        },
        type: 'text',
        info: [],
      },
    },
    {
      id: 'TX002',
      name: 'TX002',
      parentId: 'Texts',
      type: 'element',
      properties: {
        coords: {
          top: '6978.838',
          left: '9925.887999999999',
        },
        size: {
          height: '230.747',
          width: '670.564',
        },
        type: 'text',
        info: [],
      },
    },
    {
      id: 'TX003',
      name: 'TX003',
      parentId: 'Texts',
      type: 'element',
      properties: {
        coords: {
          top: '6840.976000000001',
          left: '9939.130000000001',
        },
        size: {
          height: '89.60900000000001',
          width: '532.607',
        },
        type: 'text',
        info: [],
      },
    },
    {
      id: 'TX004',
      name: 'TX004',
      parentId: 'Texts',
      type: 'element',
      properties: {
        coords: {
          top: '5823.571',
          left: '9937.916000000001',
        },
        size: {
          height: '955.967',
          width: '643.3710000000001',
        },
        type: 'text',
        info: [],
      },
    },
    {
      id: 'TX005',
      name: 'TX005',
      parentId: 'Texts',
      type: 'element',
      properties: {
        coords: {
          top: '382.108',
          left: '9940.155999999999',
        },
        size: {
          height: '350.35900000000004',
          width: '617.171',
        },
        type: 'text',
        info: [],
      },
    },
    {
      id: 'TX006',
      name: 'TX006',
      parentId: 'Texts',
      type: 'element',
      properties: {
        coords: {
          top: '5409.155',
          left: '7791.626',
        },
        size: {
          height: '321.79200000000003',
          width: '1432.8639999999998',
        },
        type: 'text',
        info: [],
      },
    },
    {
      id: 'TX007',
      name: 'TX007',
      parentId: 'Texts',
      type: 'element',
      properties: {
        coords: {
          top: '5375.4039999999995',
          left: '941.338',
        },
        size: {
          height: '1945.439',
          width: '1432.8639999999998',
        },
        type: 'text',
        info: [],
      },
    },
    {
      id: 'TX008',
      name: 'TX008',
      parentId: 'Texts',
      type: 'element',
      properties: {
        coords: {
          top: '5372.263000000001',
          left: '3949.954',
        },
        size: {
          height: '1351.882',
          width: '1432.863',
        },
        type: 'text',
        info: [],
      },
    },
    {
      id: 'TX009',
      name: 'TX009',
      parentId: 'Texts',
      type: 'element',
      properties: {
        coords: {
          top: '5355.927000000001',
          left: '2450.88',
        },
        size: {
          height: '188.43099999999998',
          width: '1432.8649999999998',
        },
        type: 'text',
        info: [],
      },
    },
    {
      id: 'TX010',
      name: 'TX010',
      parentId: 'Texts',
      type: 'element',
      properties: {
        coords: {
          top: '5549.7029999999995',
          left: '2906.254',
        },
        size: {
          height: '1758.578',
          width: '819.1500000000001',
        },
        type: 'text',
        info: [],
      },
    },
    {
      id: 'TX011',
      name: 'TX011',
      parentId: 'Texts',
      type: 'element',
      properties: {
        coords: {
          top: '2387.7029999999995',
          left: '9945.254',
        },
        size: {
          height: '2402.578',
          width: '375.1500000000001',
        },
        type: 'text',
        info: [],
      },
    },
  ],
};
